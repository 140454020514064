import React from "react";
import styled from "styled-components";
import LoginNew from "../loginNew";
import "./styles.css";

function BaseLogin( token, setToken ) {

    return (
        <Container>
            <Conteudo>
                <Form>
                    <LoginNew token={token} setToken={setToken}/>        
                </Form>
            </Conteudo>
        </Container>
    );
}

export default BaseLogin;


const Container = styled.div`
  background-color: var(--color-white);
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
  overflow: hidden;
`;

const Conteudo = styled.div`
  background-color: var(--color-white);
  display: flex;
  width: 100%;
  flex-direction: row;
  padding: 5% 15% 5% 15%;
  font-family: "Century Gothic";
  justify-content: center;
  align-items: center;
`;

const Form = styled.div`
  flex: 1;
  width: 100%;
`;