import "./styles.css";
import React, { useState, useEffect } from "react";
import mosaic from "../../assets/mosaic.png";
import styled from "styled-components";
import { FiEyeOff, FiEye } from "react-icons/fi";
import Requests from "../../configs/requets";
import imgAraxa3 from "../../assets/images/Imagem04.jpg";
import imgAraxa1 from "../../assets/images/slide-img-2.jpg";
import imgAraxa2 from "../../assets/images/mosaicBackground3.png";

function LoginNew(token, setToken) {
    const [loginInput, setLoginInput] = useState("");
    const [senhaInput, setSenhaInput] = useState("");
    const [passwordType, setPasswordType] = useState("password");
    const [isVisible, setIsVisible] = useState(false);
    const imagensSlider = [imgAraxa3, imgAraxa2, imgAraxa1];
    const [imagemSliderAtiva, setImagemSliderAtiva] = useState(0);
    const [contador, setContador] = useState(0);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const obj = {
            email: loginInput,
            password: senhaInput,
        };

        Requests.login(obj)
            .then((res) => {
                if (res.user) {
                    sessionStorage.setItem(
                        "usuarioLogado",
                        JSON.stringify(res.user)
                    );
                    window.location.href = "/type-gateway";
                } else {
                    alert("Usuário ou senha incorretos");
                }
            })
            .catch((err) => {
                console.log("ERRRRRROOOOOOO -> ", err);
            });
    };

    useEffect(() => {
        isVisible ? setPasswordType("text") : setPasswordType("password");
    }, [isVisible]);

    useEffect(() => {
        let time = 5000;

        function start() {
            setInterval(() => {
                setImagemSliderAtiva((cont) => {
                    return cont + 1;
                });
            }, time);
        }

        start();
    }, []);

    useEffect(() => {
        if (contador > 0) {
            setTimeout(() => {
                setContador(contador - 1);
            }, 1000);
        }
    }, [contador]);

    const handleForgotPass = () => {
        setContador(60);
        if (loginInput) {
            const obj = {
                email: loginInput,
            };
            //envia a request de solicitação de nova senha
            Requests.forgotPass(obj)
                .then((res) => {
                    if (res) {
                        if (res.invalido) {
                            alert(res.error);
                        } else {
                            alert("Uma nova senha foi enviada para seu email!");
                        }
                    }
                })
                .catch((res, err) => {
                    console.log(res, err);
                });
        } else {
            alert(
                "Insira seu email no campo 'Email' e clique novamente em 'redefinir senha'"
            );
        }
    };

    return (
        <Container>
            <Conteudo>
                <Form onSubmit={handleSubmit}>
                    <ContainerImg>
                        <Imagem src={mosaic} alt="" />
                    </ContainerImg>
                    <Gapsa>Infra</Gapsa>
                    <LoginForm>
                        <label>
                            Email
                            <InputEmail
                                className="col-12"
                                type="text"
                                placeholder="Usuário"
                                value={loginInput}
                                onChange={(e) => {
                                    setLoginInput(e.target.value);
                                }}
                            />
                        </label>
                        <label>
                            Senha
                            <InputPassword>
                                <input
                                    type={passwordType}
                                    placeholder="senha"
                                    value={senhaInput}
                                    onChange={(e) => {
                                        setSenhaInput(e.target.value);
                                    }}
                                />
                                <div
                                    className="eyes-mobile"
                                    onClick={() => setIsVisible(!isVisible)}
                                >
                                    {isVisible ? <FiEyeOff /> : <FiEye />}
                                </div>
                            </InputPassword>
                        </label>
                        <Buttons>
                            <button type="submit">
                                <span>ENTRAR</span>
                            </button>
                        </Buttons>
                        {contador === 0 && (
                            <ForgotPassword onClick={handleForgotPass}>
                                Esqueci minha senha
                            </ForgotPassword>
                        )}
                        {contador > 0 && (
                            <p style={{ fontSize: 14 }}>
                                Não recebeu email? Tente novamente em {contador}
                                s.
                            </p>
                        )}
                    </LoginForm>
                </Form>
                <Slider id="slider">
                    {imagensSlider.map((img, i) => {
                        return (
                            <img
                                alt="Mosaic"
                                className={
                                    i ===
                                    imagemSliderAtiva % imagensSlider.length
                                        ? "selected"
                                        : ""
                                }
                                src={img}
                            />
                        );
                    })}
                </Slider>
            </Conteudo>
        </Container>
    );
}

export default LoginNew;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
`;

const ContainerImg = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Imagem = styled.img`
    width: 70%;
`;

const Conteudo = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #fff;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80%;
    box-shadow: 0px 5px 35px rgba(0, 0, 0, 0.25);
    border-radius: 25px 25px 25px 25px;
`;

const Form = styled.form`
        height: 100%;
        width: 50%;
        border-radius: 25px 0px 0px 25px;
        padding: 30px 55px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;

        @media (max-width: 768px) {
            width: 100%;
            padding: 19px;
        }
    }
`;

const Gapsa = styled.p`
    color: #00544d;
    font-weight: bold;
    font-size: 1.3em;
    margin: 0;
`;

const LoginForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;

    label {
        font-size: 1em;
        color: black;
        display: flex;
        flex-direction: column;
        margin-bottom: 5%;
    }
`;

const InputEmail = styled.input`
    padding: 2%;
    padding-left: 2%;
    padding-top: 3%;
    border: 0;
    border-bottom: 3px solid #cbe5e3;
    border-radius: 3px;
    color: black;
    font-family: Century Gothic;
    &:focus {
        outline: none;
    }
    &:hover {
        cursor: pointer;
    }
`;

const InputPassword = styled.div`
    padding: 2%;
    padding-left: 2%;
    padding-top: 3%;
    border: 0;
    border-bottom: 3px solid #cbe5e3;
    border-radius: 3px;
    color: black;
    font-family: Century Gothic;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    &:hover {
        cursor: pointer;
    }

    input {
        flex: 1;
        border-width: 0px;
    }

    input:focus {
        outline: none;
    }

    @media (max-width: 768px) {
        gap: 0;

        input {
            flex: initial;
        }
    }
`;

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
        font-size: 1em;
        width: 100%;
        margin: 5px;
        padding: 10px;
        text-align: center;
        text-transform: uppercase;
        transition: 0.5s;
        background-size: 200% auto;
        color: white;
        border-radius: 10px;
        border: none;

        background-image: linear-gradient(
            45deg,
            #00544d 0%,
            #30978f 51%,
            #30978f 100%
        );
        cursor: pointer;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
    }

    button:hover {
        background-position: right center;
        color: #fff;
        text-decoration: none;
    }

    button:active {
        transform: scale(0.95);
    }
`;

const Slider = styled.div`
    height: 100%;
    width: 50%;
    border-radius: 0px 25px 25px 0px;
    position: relative;

    img {
        opacity: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 800ms;
        border-radius: 0px 25px 25px 0px;
    }

    .selected {
        opacity: 1;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;

export const ForgotPassword = styled.p`
    display: flex;
    justify-content: center;
    color: #00544d;
    cursor: pointer;
    padding: 2%;
    font-size: 14px;
`;
