import Axios from "axios";
// Axios.defaults.baseURL = "http://localhost:3001";
Axios.defaults.baseURL = "https://restaurantearaxabackend.directy.com.br/" // Online

export default class Requests {
  static async login(obj) {
    const link = "/auth/login";
    let content;
    await Axios.post(link, obj)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  }

  //request para caso o usuario esqueça a senha
  static forgotPass = async (object) => {
    let content;
    await Axios.post("/auth/forgotPassword", object)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  //request para criar usuario
  static createUser = async (object, config) => {
    const user = "/user/create";
    let content;
    await Axios.post(user, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  //request para usuario trocar a senha
  static changePass = async (object) => {
    let content;
    await Axios.put("/user/changePassword", object)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // request para exibir todos os usuarios
  static getUserList = async (config) => {
    const user = "/user/user-list";
    let content;
    await Axios.get(user, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((err) => {
        return err;
      });
    return content;
  };

  // request para exibir todos os usuarios que são aprovadores
  static getUserListApprovers = async (config) => {
    const user = "/user/user-approvers";
    let content;
    await Axios.get(user, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((err) => {
        return err;
      });
    return content;
  };

  // request para excluir um usuario
  static async deleteUser(usersId, objeto, config) {
    const user = `/user/user-delete/${usersId}`;
    let content;
    await Axios.delete(user, objeto, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((err) => {
        return err;
      });
    return content;
  }

  // RESTAURANTE

  //request para criar uma requisição de pedido
  static createRequest = async (object, config) => {
    const request = "/request/request-service";
    let content;
    await Axios.post(request, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // Listar os produtos
  static getRequestList = async (object, config) => {
    const request = "/request/request-list-service";
    let content;
    await Axios.get(request, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // aceitar uma solicitação
  static async authorizeRequest(requestId) {
    const endpoint = `/request/request-service/${requestId}/authorize`;
    let content;
    await Axios.put(endpoint)
      .then((res) => {
        console.log("sucesso na request")
        const response = res.data;
        content = response;
        console.log("content", content)
        return content
      })
      .catch((error) => {
        console.log("fail na request")
        console.log(error.response.data.message);
        return error.response.data;
      });
  }

  // rejeitar uma solicitação
  static async rejectRequest(requestId) {
    const endpoint = `/request/request-service/${requestId}/reject`;
    let content;
    await Axios.put(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  }

  //request para criar um produto
  static createProduct = async (object, config) => {
    const product = "/products/products-create";
    let content;
    await Axios.post(product, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  //listar produdos
  static getProductsList = async (object, config) => {
    const product = "/products/products-list";
    try {
      const response = await Axios.get(product, object, config);
      return response.data;
    } catch (error) {
      console.error("Erro ao obter a lista de produtos", error);
      throw error;
    }
  };

  // editar produtos
  static async updateProduct(productId, object, config) {
    const product = `/products/products/${productId}`;
    let content;
    await Axios.put(product, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  //request para criar tablita
  static createTablita = async (object, config) => {
    const tablita = "/tablita/tablita-create";
    let content;
    await Axios.post(tablita, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  //listar tablita
  static getTablitaList = async (object, config) => {
    const tablita = "/tablita/tablita-list";
    let content;
    await Axios.get(tablita, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // editar tablita
  static async updateTablita(tablitaId, object, config) {
    const tablita = `/tablita/tablitas/${tablitaId}`;
    let content;
    await Axios.put(tablita, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  // relatorio de produtos
  static getReportProducts = async (startDate, endDate) => {
    const endpoint = `/report/report-products?date_start=${startDate}&date_end=${endDate}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  //request para assinar a uma folha no restaurante
  static createSignature = async (object, config) => {
    const signature = "/signature/request-signature";
    let content;
    await Axios.post(signature, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // listagem de assinaturas
  static getReportSignature = async (startDate, endDate) => {
    const endpoint = `/listing/report-signature?date_start=${startDate}&date_end=${endDate}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  // relatorio de refeição mosaic
  static getReportMealMosaic = async (startDate, endDate, unit) => {
    const endpoint = `/report/report-meal-mosaic?date_start=${startDate}&date_end=${endDate}&tipo_refeicao=${unit}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  // relatorio de refeição terceiros
  static getReportOutsourcingMosaic = async (startDate, endDate, unit) => {
    const endpoint = `/report/report-meal-outsourcing?date_start=${startDate}&date_end=${endDate}&refeicao=${unit}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  // relatorio de refeição visitantes
  static getReportVisitantMosaic = async (startDate, endDate, unit) => {
    const endpoint = `/report/report-meal-visitant?date_start=${startDate}&date_end=${endDate}&refeicao=${unit}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  // relatorio de refeição spot
  static getReportSpotMosaic = async (startDate, endDate, unit) => {
    const endpoint = `/report/report-meal-spot?date_start=${startDate}&date_end=${endDate}&tipo_refeicao=${unit}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };


  // MEIO-AMBIENTE

  //request para criar um residuo
  static createLeavings = async (object, config) => {
    const leavings = "/leavings/leavings-create";
    let content;
    await Axios.post(leavings, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  //listar residuos
  static getLeavingsList = async (object, config) => {
    const leavings = "/leavings/leavings-list";
    let content;
    await Axios.get(leavings, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // editar residuos
  static async updateLeavings(leavingsId, object, config) {
    const leavings = `/leavings/leavings/${leavingsId}`;
    let content;
    await Axios.put(leavings, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  // request para excluir um resíduo
  static async deleteLeavings(leavingsId, objeto, config) {
    const leavings = `/leavings/leavings-delete/${leavingsId}`;
    let content;
    await Axios.delete(leavings, objeto, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((err) => {
        return err;
      });
    return content;
  }

  // request para criar uma movimento de descarte
  static createMovementWaste = async (object, config) => {
    const movement = "/movement/movement-waste";
    let content;
    await Axios.post(movement, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // Listar as solicitações de descarte
  static getMovementList = async (object, config) => {
    const movement = "/movement/movement-list-waste";
    let content;
    await Axios.get(movement, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // Listar as solicitações de descarte com status_conference aguardando
  static getMovementListAguardando = async (object, config) => {
    const movement = "/movement/movement-list-waste/aguardando";
    let content;
    await Axios.get(movement, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // Listar as solicitações de descarte com status_conference conferir ticket
  static getMovementListAguardandoConference = async (object, config) => {
    const movement = "/movement/movement-list-waste/aguardando-conference";
    let content;
    await Axios.get(movement, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // Listar as solicitações de descarte com status_conference finalizado
  static getMovementListFinalizado = async (object, config) => {
    const movement = "/movement/movement-list-waste/finalizado";
    let content;
    await Axios.get(movement, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  };

  // aceitar uma solicitação
  static async authorizeMovementWaste(movementId, formData) {
    const endpoint = `/movement/movement-waste/${movementId}/authorize`;
    // let content;
    console.log("formData no axios antes de ir pro back", formData)
    await Axios.put(endpoint, formData)
      .then((res) => {
        console.log("res sucesso", res)
        const response = res.data;
        return response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        return error.response.data;
      });
    // return content;
  }


  // rejeitar uma solicitação
  static async rejectMovementWaste(movementId) {
    const endpoint = `/movement/movement-waste/${movementId}/reject`;
    let content;
    await Axios.put(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  }

  // request para criar(no caso edita, pois cria com valor '') a conferencia de descarte
  static async createConferenceWaste(movementId, movementData) {
    const endpoint = `/movement/movement-waste/${movementId}/conference`;
    try {
      const response = await Axios.put(endpoint, movementData);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data;
    }
  }

  // request para conferir o ticket de pesagem
  static async createConferenceTicket(movementId, movementData) {
    const endpoint = `/movement/movement-waste/${movementId}/conference-ticket`;
    try {
      const response = await Axios.put(endpoint, movementData);
      return response.data;
    } catch (error) {
      console.log(error.response.data.message);
      return error.response.data;
    }
  }

  // request para pegar o ultimo numero da requisição criada do banco
  static async getLastRegistrationNumber() {
    const endpoint = "/request/request-service/get-registration";
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  // relatorio de residuos
  static getReportWastes = async (startDate, endDate, residue) => {
    const endpoint = `/waste/report-waste?date_start=${startDate}&date_end=${endDate}&residue=${residue}`;
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((error) => {
        console.log(error.response.data.message);
        content = error.response.data;
      });
    return content;
  };

  // editar um relatorio de residuos
  static async updateReport(movementId, object, config) {
    const movements = `/movement/movement-waste/${movementId}/report`;
    let content;
    await Axios.put(movements, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  // request para pegar o ultimo numero da requisição criada do banco para o MID
  static async getLastRegistrationNumberMid() {
    const endpoint = "/movement/movement-waste/get-registration";
    let content;
    await Axios.get(endpoint)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }

  // request para excluir uma movimentação do mid que não confere os dados
  static async deleteCMD(movementCMD, objeto, config) {
    const movement = `/movement/movement-waste/${movementCMD}`;
    let content;
    await Axios.delete(movement, objeto, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((err) => {
        return err;
      });
    return content;
  }

  // request para mostrar imagem do mid
  static async imageMID(movementId, object, config) {
    const movements = `/movement/movement-list-waste/${movementId}/image`;
    let content;
    await Axios.get(movements, object, config)
      .then((res) => {
        const response = res.data;
        content = response;
      })
      .catch((res, err) => {
        return err;
      });
    return content;
  }
}
