import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Col,
  Table,
  Modal,
  Form,
  Row,
  Button,
  Alert,
  Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Header from "../../components/template/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";

const ControlDelivery = (props) => {
  const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [requestList, setRequestList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [unit, setUnit] = useState(usuarioLogado.unit);
  const userSetor = usuarioLogado.sector;
  const userType = usuarioLogado.type;

  const updateCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;

    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const updatedCurrentItems = requestList
      .filter((tb) => tb.unit === unit)
      .slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(updatedCurrentItems);
  };


  //mudei aqui
  useEffect(() => {
    updateCurrentItems();
    // eslint-disable-next-line
  }, [currentPage, requestList]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentItems([]);
  };

  const handleSelectChangeUnit = async (event) => {
    const selectedUnit = event.target.value;
    setUnit(selectedUnit);
    fetchRequestList(selectedUnit);
  };

  const fetchRequestList = async (selectedUnit) => {
    try {
      const response = await Requests.getRequestList();
      let updatedDelivery = response.filter((tb) => tb.unit === selectedUnit);

      if (userType === "Aprovador") {
        updatedDelivery = updatedDelivery.filter(
          (request) => request.sector === userSetor
        );
      }

      updatedDelivery.sort((a, b) => {
        return moment(b.date).valueOf() - moment(a.date).valueOf();
      });

      setRequestList(updatedDelivery);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRequestList(unit);
    // eslint-disable-next-line
  }, [unit]);

  const openModal = (request) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleAuthorize = async () => {
    console.log("Entrou aqui")
    try {
      await Requests.authorizeRequest(selectedRequest.id_request);
      const updatedRequests = requestList.map((request) => {
        console.log("Esse é o request autorizar:", request)
        if (request.registration === selectedRequest.registration) {
          console.log("entrou autorizar request:", request)
          return { ...request, status: "autorizado" };
        }
        return request;
      });
      setRequestList(updatedRequests);
      setSuccessMessage(true);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  const handleNegar = async () => {
    console.log("Entrou aqui negar")
    try {
      await Requests.rejectRequest(selectedRequest.id_request);
      const updatedRequests = requestList.map((request) => {
        console.log("Esse é o request negar:", request)
        if (request.registration === selectedRequest.registration) {
          console.log("entrou negar request:", request)
          return { ...request, status: "negado" };
        }
        return request;
      });
      setRequestList(updatedRequests);
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  if (!userAllowed(["Administrador", "Aprovador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SidebarInfra />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Controle de Entrega</CardUser>
              <SpanSubtitle>
                Faça o controle de entrega dos seus produtos
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm mobile-table-products">
            <Container>
              {successMessage && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessage(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Requisição feita com sucesso!
                </Alert>
              )}
              {userType === "Administrador" ? (
                <Col sm={12} className="d-flex justify-content-end mb-4">
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicUnit">
                      <Form.Label className="labelControl">Unidade:</Form.Label>
                      <Form.Select
                        value={unit}
                        onChange={handleSelectChangeUnit}
                        aria-label="Selecione um tipo"
                        style={{ height: "45px" }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a unidade
                        </option>
                        <option value="Araxá">Araxá</option>
                        <option value="Patrocinio">Patrocínio</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Col>
              ) : (
                <Col sm={12} className="d-flex justify-content-end mb-4">
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicUnit">
                      <Form.Label className="labelControl">Unidade:</Form.Label>
                      <Form.Select
                        value={unit}
                        onChange={handleSelectChangeUnit}
                        disabled
                        aria-label="Selecione um tipo"
                        style={{ height: "45px" }}
                        required
                      >
                        <option value="" disabled>
                          Selecione a unidade
                        </option>
                        <option value="Araxá">Araxá</option>
                        <option value="Patrocinio">Patrocínio</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Col>
              )}
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <>
                  <StyledTable striped bordered hover>
                    <thead>
                      <tr style={{ textAlign: "center" }}>
                        <th>Nº Requisição</th>
                        <th>Requisitante</th>
                        <th>Setor</th>
                        <th>Produto</th>
                        <th>Data</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((request) => (
                        <tr
                          key={request.registration}
                          style={{
                            textAlign: "center",
                          }}
                          onClick={() => openModal(request)}
                        >
                          <td>{request.registration}</td>
                          <td>{request.name}</td>
                          <td>{request.sector}</td>
                          <td>{request.products}</td>
                          <td>{moment(request.date).format("DD/MM/YYYY")}</td>
                          <td>
                            <StyledFontAwesomeIconSignature
                              icon={
                                request.status === "autorizado"
                                  ? faCircleCheck
                                  : request.status === "negado"
                                    ? faCircleXmark
                                    : faUserPen
                              }
                              status={request.status}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </StyledTable>
                  {requestList.length > itemsPerPage && (
                    <Pagination className="pagination-style">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="button-pagination-pass"
                      >
                        {"<"}
                      </Button>
                      {Array.from({
                        length: Math.ceil(requestList.length / itemsPerPage),
                      }).map((_, index) => {
                        if (
                          index + 1 === currentPage ||
                          (index + 2 === currentPage &&
                            currentPage !==
                            Math.ceil(requestList.length / itemsPerPage)) ||
                          index === currentPage
                        ) {
                          return (
                            <Pagination.Item
                              className="button-pagination"
                              key={index}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        } else if (
                          (index === currentPage - 2 && index !== 0) ||
                          (index === currentPage + 1 &&
                            index !==
                            Math.ceil(requestList.length / itemsPerPage) - 1)
                        ) {
                          //   return <Pagination.Ellipsis key={index} />;
                        }
                        return null;
                      })}
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(requestList.length / itemsPerPage)
                        }
                        className="button-pagination-pass"
                      >
                        {">"}
                      </Button>
                    </Pagination>
                  )}
                </>
              )}
              <Modal show={showModal} onHide={closeModal} className="modal-lg">
                <Modal.Header closeButton>
                  <Modal.Title>Detalhes do Pedido</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Filial:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              value={selectedRequest?.unit}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicSector">
                            <Form.Label className="labelControl">
                              Setor:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              disabled
                              value={selectedRequest?.sector}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicName">
                            <Form.Label>Nome:</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.name}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicObs">
                            <Form.Label className="mr-2">Produto:</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.products}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="labelControl mr-2">
                              Data:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={
                                selectedRequest &&
                                moment(selectedRequest.date).format(
                                  "DD/MM/YYYY"
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicTime">
                            <Form.Label className="labelControl mr-2">
                              Horário:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={
                                selectedRequest &&
                                moment(selectedRequest.date).format("HH:mm")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="mr-2">
                              Requisição:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.registration}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicAmount">
                            <Form.Label className="labelControl mr-2">
                              Quantidade:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="number"
                              value={selectedRequest?.amount}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="mr-2">
                              Centro de custo:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.cost_center}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicAmount">
                            <Form.Label className="labelControl mr-2">
                              Conta razão:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="number"
                              value={selectedRequest?.count}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="labelControl mr-2">
                              Data de entrega:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={
                                selectedRequest &&
                                moment(selectedRequest.date_delivery).format(
                                  "DD/MM/YYYY"
                                )
                              }
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicAmount">
                            <Form.Label className="labelControl mr-2">
                              Local de entrega:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.local_delivery}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Col sm={12}>
                        <Form.Group controlId="formBasicAmount">
                          <Form.Label className="labelControl mr-2">
                            Observação:
                          </Form.Label>
                          <Form.Control
                            disabled
                            type="text"
                            value={selectedRequest?.observation}
                          />
                        </Form.Group>
                      </Col>
                    </DivForm>
                    {selectedRequest &&
                      selectedRequest.status === "pendente" && (
                        <>
                          <Col sm={12} xs={12}>
                            <Button
                              variant="primary"
                              type="button"
                              className="col-12 mt-3 btn btn-danger"
                              onClick={handleNegar}
                            >
                              NEGAR
                            </Button>
                          </Col>
                          <Col sm={12} xs={12}>
                            { }
                            <Button
                              variant="primary"
                              type="button"
                              className="buttonAuthorize"
                              onClick={handleAuthorize}
                            >
                              Autorizar
                            </Button>
                          </Col>
                        </>
                      )}
                  </Form>
                </Modal.Body>
              </Modal>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default ControlDelivery;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledTable = styled(Table)`
  margin-top: 25px;
`;

const StyledFontAwesomeIconSignature = styled(FontAwesomeIcon)`
  color: ${(props) => {
    switch (props.status) {
      case "normal":
        return "gray";
      case "autorizado":
        return "green";
      case "negado":
        return "red";
      default:
        return "gray";
    }
  }};
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;