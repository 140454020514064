import React, { useEffect, useState } from "react";
import {
    Container,
    Card,
    Col,
    Table,
    Modal,
    Form,
    Row,
    Button,
    Pagination,
    Alert
} from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import nada from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faQuestionCircle,
    faCircleCheck
} from "@fortawesome/free-solid-svg-icons";

const ConferenceTicket = (props) => {
    const [showModal, setShowModal] = useState(false);
    // const [showImage, setShowImage] = useState(false);
    const [movementList, setMovementList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [currentItems, setCurrentItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const [activeButton, setActiveButton] = useState("descarte");
    const [disabled, setDisabled] = useState(false);

    //ticket
    const [radioMovimentOption, setRadioMovementOption] = useState("");
    const [radioOption, setRadioOption] = useState("");
    const [justificativa, setJustificativa] = useState("");
    const statusConferece = "finalizado";

    const updateCurrentItems = () => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const updatedCurrentItems = movementList
            .slice(indexOfFirstItem, indexOfLastItem);
        setCurrentItems(updatedCurrentItems);
    };

    useEffect(() => {
        updateCurrentItems();
        // eslint-disable-next-line
    }, [currentPage, movementList]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setCurrentItems([]);
    };

    const fetchMovementList = async () => {
        try {
            const response = await nada.getMovementListAguardandoConference();
            const movementListWithImages = response.map((movement) => {
                if (movement.image_conference) {
                    const imageArray = new Uint8Array(movement.image_conference.data);

                    const base64String = btoa(
                        imageArray.reduce(
                            (data, byte) => data + String.fromCharCode(byte),
                            ""
                        )
                    );

                    const imageType = movement.image_conference.type.split("/")[1];
                    const imageUrl = `data:image/${imageType};base64,${base64String}`;

                    return { ...movement, image_conference: imageUrl };
                }
                return movement;
            });

            movementListWithImages.sort((a, b) => {
                return (
                    moment(b.date_solicitation).valueOf() -
                    moment(a.date_solicitation).valueOf()
                );
            });

            setMovementList(movementListWithImages);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            setDisabled(true);

            const requestData = {
                id_movement: selectedRequest.id_movement,
                ticket_weighing: radioOption,
                justification: justificativa,
                delivered_waste: radioMovimentOption,
                status_conference: statusConferece,
            };

            console.log('Request Data:', requestData);

            const res = await nada.createConferenceTicket(
                selectedRequest.id_movement,
                requestData
            );

            if (res && !res.invalido) {
                const updatedMovementList = movementList.map((request) =>
                    request.id_movement === selectedRequest.id_movement
                        ? {
                            ...request,
                            ticket_weighing: radioOption,
                            justification: justificativa,
                            delivered_waste: radioMovimentOption,
                            status_conference: statusConferece,
                        }
                        : request
                );

                setMovementList(updatedMovementList);
                setSuccessMessage(true);
                setTimeout(() => {
                    window.location.href = "/home-environment";
                }, 750);
            } else {
                alert("Erro ao fazer conferência do ticket de pesagem");
            }
        } catch (error) {
            console.error(error);
            alert("Erro ao fazer conferência do ticket de pesagem");
        } finally {
            setDisabled(false);
        }
    };

    const ExibirImagem = ({ imageUrl }) => {
        // const [showImage, setShowImage] = useState(false);

        const handleViewImage = () => {
            if (imageUrl) {
                const overlayElement = document.createElement("div");
                overlayElement.style.cssText = `
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.8);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                `;

                let imgElement = document.createElement("img");

                imgElement.onload = () => {
                    if (imgElement && imgElement.complete) {
                        overlayElement.appendChild(imgElement);
                    } else {
                        showNoImageMessage();
                    }
                };

                imgElement.onerror = () => {
                    showNoImageMessage();
                };

                imgElement.src = imageUrl;
                imgElement.alt = "Imagem";
                imgElement.style.cssText = `
                    max-height: 80vh;
                    max-width: 100%;
                `;

                const showNoImageMessage = () => {
                    const noImageMessage = document.createElement("div");
                    noImageMessage.textContent = "Nenhuma imagem disponível";
                    noImageMessage.style.cssText = `
                        color: white;
                        font-size: 20px;
                    `;

                    overlayElement.appendChild(noImageMessage);
                };

                overlayElement.addEventListener("click", () => {
                    document.body.removeChild(overlayElement);
                });

                document.body.appendChild(overlayElement);
            } else {
                alert("Não há imagem disponível");
            }
        };

        return (
            <>
                {imageUrl ? (
                    <Button className="buttonSolicitar" onClick={handleViewImage}>
                        Visualizar Imagem
                    </Button>
                ) : (
                    <p>Não há imagens</p>
                )}
            </>
        );
    };


    useEffect(() => {
        fetchMovementList();
        // eslint-disable-next-line
    }, []);

    const openModal = (request) => {
        setSelectedRequest(request);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleButtonClick = (button) => {
        setActiveButton(button);
    };

    const handleRadioOptionChange = (option) => {
        setRadioOption(option);
    };

    if (!userAllowed(["Administrador", "Aprovador"])) {
        window.location.href = "/home";
        alert("Não permitido para o seu usuário");
        return <></>;
    }

    return (
        <>
            <Header />
            <div style={{ display: "flex" }}>
                <SidebarInfraEnvironment />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>Conferência do Ticket de Pesagem</CardUser>
                            <SpanSubtitle>
                                Conferência se o ticket de pesagem confere com o descrito no descarte
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <Col xs={12} className="controlForm mobile-table-products">
                        <Container>
                            {successMessage && (
                                <Alert
                                    variant="success"
                                    onClose={() => setSuccessMessage(false)}
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon icon={faCircleCheck} />
                                    Conferência feita com sucesso!
                                </Alert>
                            )}
                            {loading ? (
                                <p>Carregando...</p>
                            ) : (
                                <>
                                    <div className="table-movement">
                                        <StyledTable striped bordered hover>
                                            <thead>
                                                <tr
                                                    style={{
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    <th>Nº MID</th>
                                                    <th>Representante</th>
                                                    <th>Setor gerador/Empresa contratada</th>
                                                    <th>Resíduo</th>
                                                    <th>Data</th>
                                                    <th>Conferência</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentItems.map((request) => (
                                                    <tr
                                                        key={request.id_movement}
                                                        style={{
                                                            textAlign: "center",
                                                        }}
                                                        onClick={() => openModal(request)}
                                                    >
                                                        <td>{request.amount_residue}</td>
                                                        <td>{request.representative}</td>
                                                        <td>{request.setor_generator}</td>
                                                        <td>{request.residue}</td>
                                                        <td>
                                                            {moment(request.date_solicitation).format(
                                                                "DD/MM/YYYY"
                                                            )}
                                                        </td>
                                                        <td>{request.status_conference}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </StyledTable>
                                    </div>
                                    {movementList.length > itemsPerPage && (
                                        <Pagination className="pagination-style">
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                className="button-pagination-pass"
                                            >
                                                {"<"}
                                            </Button>
                                            {Array.from({
                                                length: Math.ceil(movementList.length / itemsPerPage),
                                            }).map((_, index) => {
                                                if (
                                                    index + 1 === currentPage ||
                                                    (index + 2 === currentPage &&
                                                        currentPage !==
                                                        Math.ceil(movementList.length / itemsPerPage)) ||
                                                    index === currentPage
                                                ) {
                                                    return (
                                                        <Pagination.Item
                                                            className="button-pagination"
                                                            key={index}
                                                            active={index + 1 === currentPage}
                                                            onClick={() => handlePageChange(index + 1)}
                                                        >
                                                            {index + 1}
                                                        </Pagination.Item>
                                                    );
                                                } else if (
                                                    (index === currentPage - 2 && index !== 0) ||
                                                    (index === currentPage + 1 &&
                                                        index !==
                                                        Math.ceil(movementList.length / itemsPerPage) - 1)
                                                ) {
                                                    //   return <Pagination.Ellipsis key={index} />;
                                                }
                                                return null;
                                            })}
                                            <Button
                                                variant="primary"
                                                type="button"
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={
                                                    currentPage ===
                                                    Math.ceil(movementList.length / itemsPerPage)
                                                }
                                                className="button-pagination-pass"
                                            >
                                                {">"}
                                            </Button>
                                        </Pagination>
                                    )}
                                </>
                            )}
                            <Modal show={showModal} onHide={closeModal} className="modal-xl">
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Informações da Solicitação de Descarte e Conferência do
                                        Material
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Col
                                        xs={12}
                                        className="d-flex justify-content-center align-center mb-3 options-buttons-waste"
                                    >
                                        <Row>
                                            <ButtonsOptions
                                                active={activeButton === "descarte"}
                                                onClick={() => handleButtonClick("descarte")}
                                                className="col-6"
                                            >
                                                Informações do descarte
                                            </ButtonsOptions>
                                            <ButtonsOptions
                                                active={activeButton === "conferencia"}
                                                onClick={() => handleButtonClick("conferencia")}
                                                className="col-6"
                                            >
                                                Conferência do Material
                                            </ButtonsOptions>
                                            <ButtonsOptions
                                                active={activeButton === "ticket"}
                                                onClick={() => handleButtonClick("ticket")}
                                                className="col-6"
                                            >
                                                Conferência do Ticket
                                            </ButtonsOptions>
                                        </Row>
                                    </Col>
                                    {activeButton === "descarte" && (
                                        <Form>
                                            <DivForm>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicCompany">
                                                            <Form.Label className="labelControl">
                                                                Gerência:
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled
                                                                value={selectedRequest?.administration}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicSector">
                                                            <Form.Label className="labelControl">
                                                                Setor gerador:
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                disabled
                                                                value={selectedRequest?.setor_generator}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicName">
                                                            <Form.Label>Complexo industrial:</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.industrial}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicDate">
                                                            <Form.Label className="labelControl mr-2">
                                                                Data:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={
                                                                    selectedRequest &&
                                                                    moment(
                                                                        selectedRequest.date_solicitation
                                                                    ).format("DD/MM/YYYY")
                                                                }
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicObs">
                                                            <Form.Label className="mr-2">Resíduo:</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.residue}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicObs">
                                                            <Form.Label className="mr-2">
                                                                Quantidade:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.amount_residue}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicDate">
                                                            <Form.Label className="mr-2">
                                                                Tara do veículo:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.tare_vehicle}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicAmount">
                                                            <Form.Label className="labelControl mr-2">
                                                                Peso bruto do veículo:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="number"
                                                                value={selectedRequest?.gross_weight}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicDate">
                                                            <Form.Label className="mr-2">
                                                                Peso líquido:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.net_weight}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicAmount">
                                                            <Form.Label className="labelControl mr-2">
                                                                Representante:
                                                            </Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.representative}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                            </DivForm>
                                        </Form>
                                    )}
                                    {activeButton === "conferencia" && (
                                        <Form>
                                            <DivForm>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicFirstName">
                                                            <Form.Label>Veículo</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={selectedRequest?.vehicle_transport}
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicLastName">
                                                            <Form.Label>Placa</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.license_plate}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicFirstName">
                                                            <Form.Label>Motorista</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.name_motorist}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicLastName">
                                                            <Form.Label>Matricula do motorista</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.registration_motorist}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicFirstName">
                                                            <Form.Label>Responsável pela CMD</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.name_resposible}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col sm={6} xs={12}>
                                                        <Form.Group controlId="formBasicLastName">
                                                            <Form.Label>Matricula do responsavel</Form.Label>
                                                            <Form.Control
                                                                disabled
                                                                type="text"
                                                                value={selectedRequest?.registration_resposible}
                                                            />
                                                        </Form.Group>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Row>
                                                        <Col sm={6} xs={12} className="buttonForm">
                                                            <ExibirImagem
                                                                imageUrl={selectedRequest?.image_conference}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </DivForm>
                                        </Form>
                                    )}
                                    {activeButton === "ticket" && (
                                        <Form onSubmit={handleSubmit}>
                                            <DivForm>
                                                {successMessage && (
                                                    <Alert
                                                        variant="success"
                                                        onClose={() => setSuccessMessage(false)}
                                                        dismissible
                                                    >
                                                        <StyledFontAwesomeIcon icon={faCircleCheck} />
                                                        Conferência feita com sucesso!
                                                    </Alert>
                                                )}
                                                <Row>
                                                    <Form.Group
                                                        controlId="formBasicRadio"
                                                        className="mt-3"
                                                    >
                                                        <Form.Label>
                                                            O anexo foi entregue juntamente com o ticket de
                                                            pesagem emitido pela balança? A pesagem descrita
                                                            no anexo confere com o ticket?
                                                            <Tooltips>
                                                                <ButtonTooltip
                                                                    disabled
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Verificação dos documentos que devem ser entregues para o responsável da CMD (Anexo V e ticket de pesagem emitido por balança). Item que deverá ser preenchido pelo responsável da CMD"
                                                                >
                                                                    <StyledTooltipFontAwesome
                                                                        icon={faQuestionCircle}
                                                                    />
                                                                </ButtonTooltip>
                                                            </Tooltips>
                                                        </Form.Label>
                                                        <Row>
                                                            <Form.Check
                                                                style={{
                                                                    padding: "0 2.5rem",
                                                                }}
                                                                type="radio"
                                                                label="Sim"
                                                                name="radioOption"
                                                                id="radioSim"
                                                                checked={radioOption === "Sim"}
                                                                onChange={() => handleRadioOptionChange("Sim")}
                                                            />
                                                            <Form.Check
                                                                style={{
                                                                    padding: "0 2.5rem",
                                                                }}
                                                                type="radio"
                                                                label="Não"
                                                                name="radioOption"
                                                                id="radioNao"
                                                                checked={radioOption === "Não"}
                                                                onChange={() => handleRadioOptionChange("Não")}
                                                            />
                                                        </Row>
                                                    </Form.Group>
                                                    {radioOption === "Não" && (
                                                        <Form.Group controlId="formBasicJustificativa">
                                                            <Form.Label>
                                                                Justifique o motivo
                                                                <Tooltips>
                                                                    <ButtonTooltip
                                                                        disabled
                                                                        data-toggle="tooltip"
                                                                        data-placement="top"
                                                                        title="Justificativa que deverá ser preenchida pelo responsável do setor gerador, ou preposto da contratada, caso os resíduos não sejam entregues para a CMD com o ticket de pesagem emitido por balança."
                                                                    >
                                                                        <StyledTooltipFontAwesome
                                                                            icon={faQuestionCircle}
                                                                        />
                                                                    </ButtonTooltip>
                                                                </Tooltips>
                                                            </Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                placeholder="Informe a justificativa"
                                                                value={justificativa}
                                                                onChange={(e) =>
                                                                    setJustificativa(e.target.value)
                                                                }
                                                            />
                                                        </Form.Group>
                                                    )}
                                                </Row>
                                                <Row>
                                                    <Form.Group
                                                        controlId="formBasicRadio"
                                                        className="mt-3"
                                                    >
                                                        <Form.Label>
                                                            Os resíduos foram entregues para o responsável da
                                                            CMD, devidamente acondicionados e identificados?
                                                            <Tooltips>
                                                                <ButtonTooltip
                                                                    disabled
                                                                    data-toggle="tooltip"
                                                                    data-placement="top"
                                                                    title="Item que deverá ser preenchido pelo responsável da CMD."
                                                                >
                                                                    <StyledTooltipFontAwesome
                                                                        icon={faQuestionCircle}
                                                                    />
                                                                </ButtonTooltip>
                                                            </Tooltips>
                                                        </Form.Label>
                                                        <Row>
                                                            <Form.Check
                                                                style={{
                                                                    padding: "0 2rem",
                                                                }}
                                                                type="radio"
                                                                label="Sim"
                                                                name="radioMovementOption"
                                                                id="movementYes"
                                                                checked={radioMovimentOption === "Sim"}
                                                                onChange={() => setRadioMovementOption("Sim")}
                                                            />
                                                            <Form.Check
                                                                style={{
                                                                    padding: "0 2rem",
                                                                }}
                                                                type="radio"
                                                                label="Não"
                                                                name="radioMovementOption"
                                                                id="movementNo"
                                                                checked={radioMovimentOption === "Não"}
                                                                onChange={() => setRadioMovementOption("Não")}
                                                            />
                                                        </Row>
                                                    </Form.Group>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Col sm={6} xs={12} className="buttonForm">
                                                            <Button
                                                                variant="primary"
                                                                type="submit"
                                                                className="buttonSolicitar"
                                                                disabled={disabled}
                                                            >
                                                                Confirmar
                                                            </Button>
                                                        </Col>
                                                    </Col>
                                                </Row>
                                            </DivForm>
                                        </Form>
                                    )}
                                </Modal.Body>
                            </Modal>
                        </Container>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default ConferenceTicket;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledTable = styled(Table)`
  margin-top: 25px;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const ButtonsOptions = styled.button`
  background-color: transparent;
  border: none;
  padding: 1rem;
  width: 15rem;
  border-bottom: 2px solid
    ${(props) => (props.active ? "rgb(3 88 81)" : "#f3f3f3")};
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;

const Tooltips = styled.span`
  height: 10px;
  padding-left: 0.1rem;
`;

const ButtonTooltip = styled.button`
  background-color: transparent;
  border: none;
`;

const StyledTooltipFontAwesome = styled(FontAwesomeIcon)`
  color: rgb(48, 151, 143);
`;