import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Form,
  Row,
  Card,
  Button,
  Table,
  Modal,
  Pagination,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { userAllowed } from "../../configs/userAllowed";
import Requests from "../../configs/requets";
import { parseISO, startOfDay, endOfDay } from "date-fns";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const ReportSignature = () => {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [dateEnd, setDateEnd] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [reportData, setReportData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState({});
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 15;
  const [signatureImageURL, setSignatureImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reportDataAvailable, setReportDataAvailable] = useState(false);

  const updateCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;

    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const updatedCurrentItems = reportData.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    setCurrentItems(updatedCurrentItems);
  };

  useEffect(() => {
    updateCurrentItems();
    // eslint-disable-next-line
  }, [currentPage, reportData]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentItems([]);
  };

  const handleModalOpen = (report) => {
    setSelectedReport(report);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleRequestReport = async () => {
    try {
      setLoading(true);
      const startDate = startOfDay(parseISO(date));
      const endDate = endOfDay(parseISO(dateEnd));
      const response = await Requests.getReportSignature(startDate, endDate);
      setReportData(response.reports);
      setReportDataAvailable(true);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (
      selectedReport.responsible_signature &&
      selectedReport.responsible_signature.data
    ) {
      const arrayBuffer = new Uint8Array(
        selectedReport.responsible_signature.data
      ).buffer;
      const blob = new Blob([arrayBuffer], { type: "image/png" });

      console.log("Blob:", blob);

      const imageURL = URL.createObjectURL(blob);

      console.log("Object URL:", imageURL);

      setSignatureImageURL(imageURL);
    }
  }, [selectedReport.responsible_signature]);

  useEffect(() => {
    return () => {
      if (signatureImageURL) {
        URL.revokeObjectURL(signatureImageURL);
      }
    };
  }, [signatureImageURL]);

  useEffect(() => {
    console.log(signatureImageURL);
  });

  // Função para gerar PDF
  const generatePDF = () => {
    const doc = new jsPDF();

    doc.text("Relatório de Assinaturas", 10, 10);

    const rows = [];

    // Add table header as first row
    const headerRow = [
      "Data",
      "Nome do solicitante",
      "Setor",
      "Empresa",
      "Matricula",
      "Tipo de refeição",
      "Motivo",
    ];
    rows.push(headerRow);

    reportData.forEach((report) => {
      const rowData = [
        new Date(report.created_at).toLocaleDateString(),
        report.name_requester,
        report.sector,
        report.company_visitant,
        report.requester_registration,
        report.type_meal,
        report.reason,
      ];
      rows.push(rowData);
    });

    doc.autoTable({
      head: [rows[0]],
      body: rows.slice(1),
    });

    doc.save("Lista_de_assinatura.pdf");
  };

  // Função para gerar Excel
  const generateExcel = () => {
    try {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet([
        [
          "Data",
          "Nome do solicitante",
          "Setor",
          "Empresa",
          "Matricula",
          "Tipo de refeição",
          "Motivo",
        ],
      ]);

      reportData.forEach((report) => {
        XLSX.utils.sheet_add_aoa(
          ws,
          [
            [
              new Date(report.created_at).toLocaleDateString(),
              report.name_requester,
              report.sector,
              report.company_visitant,
              report.requester_registration,
              report.type_meal,
              report.reason,
            ],
          ],
          { origin: -1 }
        );
      });

      XLSX.utils.book_append_sheet(wb, ws, "Listagem_de_assinaturas");

      const wbout = XLSX.write(wb, {
        bookType: "xlsx",
        type: "array",
      });

      const blob = new Blob([wbout], { type: "application/octet-stream" });

      saveAs(blob, "Listagem_de_assinaturas.xlsx");
    } catch (error) {
      console.error("Error generating Excel:", error);
    }
  };

  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex", minHeight: "100vh" }}>
        <SidebarInfra />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Listagem de assinaturas</CardUser>
              <SpanSubtitle>
                Veja a listagem de assinaturas das pessoas que passaram no
                restaurante
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              <Form>
                <DivForm>
                  <Row>
                    <Col sm={12} xs={12}>
                      <Form.Group controlId="formBasicCompany">
                        <Form.Label className="labelControl">
                          Período:
                        </Form.Label>
                        <Col sm={12} xs={12}>
                          <Row>
                            <Col sm={6} xs={6}>
                              <Form.Control
                                type="date"
                                value={date}
                                onChange={(event) =>
                                  setDate(event.target.value)
                                }
                              />
                            </Col>
                            <Col sm={1}>
                              <SpanText>Até:</SpanText>
                            </Col>
                            <Col sm={5} xs={6}>
                              <Form.Control
                                type="date"
                                value={dateEnd}
                                onChange={(event) =>
                                  setDateEnd(event.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </DivForm>
                <Col xs={12}>
                  <Col sm={6} xs={12} className="buttonForm mb-5">
                    <Button
                      variant="primary"
                      type="button"
                      className="buttonCadaster"
                      onClick={handleRequestReport}
                      disabled={loading}
                    >
                      {loading ? "Carregando..." : "Solicitar listagem"}
                    </Button>
                  </Col>
                </Col>
                <Col>
                  {loading && (
                    <Container
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "5rem",
                      }}
                    >
                      <div className="text-center mt-3">
                        <Spinner animation="border" role="status">
                          <span className="sr-only">Carregando...</span>
                        </Spinner>
                      </div>
                    </Container>
                  )}
                  {reportData.length > 0 && (
                    <Container
                      style={{
                        marginTop: "5rem",
                      }}
                    >
                      <Col
                        xs={12}
                        sm={12}
                        className="table-movement table-conference-cmd mt-5"
                      >
                        <h4 className="mt-5 mb-3">Registros:</h4>
                        <Table striped bordered hover id="report-table">
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Nome do solicitante</th>
                              <th>Setor</th>
                              <th>Empresa</th>
                              <th>Matricula</th>
                              <th>Tipo de refeição</th>
                              <th>Motivo</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentItems.map((report) => (
                              // {reportData.map((report) => (

                              <tr
                                key={report.id_signature}
                                onClick={() => handleModalOpen(report)}
                              >
                                <td>
                                  {new Date(
                                    report.created_at
                                  ).toLocaleDateString()}
                                </td>
                                <td>{report.name_requester}</td>
                                <td>{report.sector}</td>
                                <td>{report.company_visitant ? report.company_visitant : "Mosaic"}</td>
                                <td>{report.requester_registration}</td>
                                <td>{report.type_meal}</td>
                                <td>{report.reason}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </Col>
                    </Container>
                  )}
                  {reportDataAvailable && (
                    <Container>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Button
                            variant="secondary"
                            className="buttonCadaster"
                            onClick={generatePDF}
                          >
                            Baixar PDF
                          </Button>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Button
                            variant="secondary"
                            className="buttonCadaster"
                            onClick={generateExcel}
                          >
                            Baixar Excel
                          </Button>
                        </Col>
                      </Row>
                    </Container>
                  )}
                  {reportData.length > itemsPerPage && (
                    <Pagination className="pagination-style">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="button-pagination-pass"
                      >
                        {"<"}
                      </Button>
                      {Array.from({
                        length: Math.ceil(reportData.length / itemsPerPage),
                      }).map((_, index) => {
                        if (
                          index + 1 === currentPage ||
                          (index + 2 === currentPage &&
                            currentPage !==
                              Math.ceil(reportData.length / itemsPerPage)) ||
                          index === currentPage
                        ) {
                          return (
                            <Pagination.Item
                              className="button-pagination"
                              key={index}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        } else if (
                          (index === currentPage - 2 && index !== 0) ||
                          (index === currentPage + 1 &&
                            index !==
                              Math.ceil(reportData.length / itemsPerPage) - 1)
                        ) {
                          //   return <Pagination.Ellipsis key={index} />;
                        }
                        return null;
                      })}
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(reportData.length / itemsPerPage)
                        }
                        className="button-pagination-pass"
                      >
                        {">"}
                      </Button>
                    </Pagination>
                  )}
                </Col>
              </Form>
            </Container>
          </Col>
          <Modal
            show={showModal}
            onHide={handleModalClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header closeButton>
              <Modal.Title>Informações da entrada ao restaurante</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                Data: {new Date(selectedReport.created_at).toLocaleDateString()}
              </p>
              <p>Nome: {selectedReport.name_requester}</p>
              <p>Tipo de refeição: {selectedReport.type_meal}</p>
              <p>Motivo: {selectedReport.reason}</p>
              <p>Restaurante: {selectedReport.restaurant}</p>
              <p>Setor: {selectedReport.sector}</p>
              <p>Empresa visitante: {selectedReport.company_visitant}</p>
              <p>Setor visitante: {selectedReport.sector_visitant}</p>
              <div>
                <p>Assinatura:</p>
                {signatureImageURL && (
                  <PrevUpload>
                    <StyledImg src={signatureImageURL} alt="Assinatura" />
                  </PrevUpload>
                )}
              </div>
            </Modal.Body>
          </Modal>
        </MainContainer>
      </div>
    </>
  );
};

export default ReportSignature;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const SpanText = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  text-transform: uppercase;
  padding-left: 30px;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: block;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 0.5rem;
  }
`;

const PrevUpload = styled(Col)`
  border: 1px solid rgb(48, 151, 143);
  border-radius: 5px;
  height: 7.5rem;
  width: 27rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

const StyledImg = styled.img`
  width: 100%;
  height: 90%;
`;
