import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  Col,
  Table,
  Modal,
  Form,
  Row,
  Button,
  Alert,
  Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faCircleXmark,
  faUserPen,
} from "@fortawesome/free-solid-svg-icons";

const ControlMovement = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [movementList, setMovementList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(20);
  const [formData, setFormData] = useState({
    administration: "",
    setor_generator: "",
    industrial: "",
    date_solicitation: "",
    stored_location: "",
    tare_vehicle: "",
    gross_weight: "",
    net_weight: "",
    representative: "",
  });

  const updateCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;

    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const updatedCurrentItems = movementList.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    setCurrentItems(updatedCurrentItems);
  };

  console.log("currentItenssss", currentItems)

  useEffect(() => {
    updateCurrentItems();
    // eslint-disable-next-line
  }, [currentPage, movementList]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentItems([]);
  };

  const fetchMovementList = async () => {
    try {
      const response = await Requests.getMovementList();

      response.sort((a, b) => {
        return (
          moment(b.date_solicitation).valueOf() -
          moment(a.date_solicitation).valueOf()
        );
      });

      setMovementList(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMovementList();
    // eslint-disable-next-line
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);
    setShowModal(true);
    setFormData({
      administration: request.administration,
      setor_generator: request.setor_generator,
      industrial: request.industrial,
      date_solicitation: request.date_solicitation,
      stored_location: request.stored_location,
      tare_vehicle: request.tare_vehicle,
      gross_weight: request.gross_weight,
      net_weight: request.net_weight,
      representative: request.representative,
    });
  };


  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    console.log(`Updating ${name} to ${value}`);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };


  const handleAuthorize = async () => {
    try {
      if (!formData || Object.keys(formData).length === 0) {
        console.error('FormData não está definido ou está vazio.');
        return;
      }

      console.log('FormData antes de enviar:', formData);

      const updatedMovement = await Requests.authorizeMovementWaste(
        selectedRequest.id_movement,
        formData,
      );

      console.log("formData depois", formData)

      console.log("Após a autorização updatedMovement:", updatedMovement);

      setMovementList((prevList) => {
        return prevList.map((request) =>
          request.id_movement === selectedRequest.id_movement
            ? updatedMovement
            : request
        );
      });

      closeModal();
      setSuccessMessage(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleNegar = async () => {
    try {
      await Requests.rejectMovementWaste(selectedRequest.id_movement);
      setMovementList((prevList) => {
        const updatedList = prevList.filter(
          (request) => request.id_movement !== selectedRequest.id_movement
        );
        return updatedList;
      });
      closeModal();
    } catch (error) {
      console.error(error);
    }
  };

  if (!userAllowed(["Administrador", "Aprovador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SidebarInfraEnvironment />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Controle de Movimentação de Resíduos</CardUser>
              <SpanSubtitle>
                Faça o controle de movimentação de resíduos
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm mobile-table-products">
            <Container>
              {successMessage && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessage(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Requisição feita com sucesso!
                </Alert>
              )}
              {loading ? (
                <p>Carregando...</p>
              ) : (
                <>
                  <div className="table-movement">
                    <StyledTable striped bordered hover>
                      <thead>
                        <tr
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <th>Nº MID</th>
                          <th>Representante</th>
                          <th>Setor gerador/Empresa contratada</th>
                          <th>Resíduo</th>
                          <th>Quantidade</th>
                          <th>Data</th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems
                          .filter((request) => request && request.status && request.status === "pendente")
                          .map((request) => (
                            <tr
                              key={request.id_movement}
                              style={{
                                textAlign: "center",
                              }}
                              onClick={() => openModal(request)}
                            >
                              <td>{request.amount_residue}</td>
                              <td>{request.representative}</td>
                              <td>{request.setor_generator}</td>
                              <td>{request.residue}</td>
                              <td>{request.net_weight}</td>
                              <td>
                                {moment(request.date_solicitation).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td>
                                <StyledFontAwesomeIconSignature
                                  icon={
                                    request && request.status === "autorizado"
                                      ? faCircleCheck
                                      : request && request.status === "negado"
                                        ? faCircleXmark
                                        : faUserPen
                                  }
                                  status={request && request.status}
                                />

                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </StyledTable>
                  </div>
                  {movementList.length > itemsPerPage && (
                    <Pagination className="pagination-style">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="button-pagination-pass"
                      >
                        {"<"}
                      </Button>
                      {Array.from({
                        length: Math.ceil(movementList.length / itemsPerPage),
                        // eslint-disable-next-line
                      }).map((_, index) => {
                        if (
                          index + 1 === currentPage ||
                          index + 2 === currentPage ||
                          index === currentPage
                        ) {
                          return (
                            <Pagination.Item
                              className="button-pagination"
                              key={index}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        }
                        if (
                          (index === currentPage - 2 && index !== 0) ||
                          (index === currentPage + 1 &&
                            index !== movementList.length / itemsPerPage - 1)
                        )
                          return null;
                      })}
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(movementList.length / itemsPerPage)
                        }
                        className="button-pagination-pass"
                      >
                        {">"}
                      </Button>
                    </Pagination>
                  )}
                </>
              )}
              <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                  <Modal.Title>
                    Detalhes da solicitação do descarte de resíduo
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Gerência:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="administration"
                              value={formData.administration || ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicSector">
                            <Form.Label className="labelControl">
                              Setor gerador:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              name="setor_generator"
                              value={formData.setor_generator || ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicName">
                            <Form.Label>Complexo industrial:</Form.Label>
                            <Form.Control
                              type="text"
                              name="industrial"
                              value={formData.industrial || ""}
                              onChange={handleInputChange}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="labelControl mr-2">
                              Data:
                            </Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={
                                selectedRequest &&
                                moment(
                                  selectedRequest?.date_solicitation
                                ).format("DD/MM/YYYY")
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicObs">
                            <Form.Label className="mr-2">Resíduo:</Form.Label>
                            <Form.Control
                              disabled
                              type="text"
                              value={selectedRequest?.residue}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicObs">
                            <Form.Label className="mr-2">
                              Acondicionamento:
                            </Form.Label>
                            <Form.Control
                              onChange={handleInputChange}
                              type="text"
                              value={formData.stored_location || ""}
                              name="stored_location"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="mr-2">
                              Tara do veículo:
                            </Form.Label>
                            <Form.Control
                              onChange={handleInputChange}
                              type="text"
                              value={formData.tare_vehicle || ""}
                              name="tare_vehicle"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicAmount">
                            <Form.Label className="labelControl mr-2">
                              Peso bruto do veículo:
                            </Form.Label>
                            <Form.Control
                              onChange={handleInputChange}
                              type="number"
                              value={formData.gross_weight || ""}
                              name="gross_weight"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicDate">
                            <Form.Label className="mr-2">
                              Peso líquido:
                            </Form.Label>
                            <Form.Control
                              onChange={handleInputChange}
                              type="text"
                              value={formData.net_weight || ""}
                              name="net_weight"
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicAmount">
                            <Form.Label className="labelControl mr-2">
                              Representante:
                            </Form.Label>
                            <Form.Control
                              onChange={handleInputChange}
                              type="text"
                              value={formData.representative || ""}
                              name="representative"
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    {selectedRequest &&
                      selectedRequest.status === "pendente" && (
                        <>
                          <Col sm={12} xs={12}>
                            <Button
                              variant="primary"
                              type="button"
                              className="col-12 mt-3 btn btn-danger"
                              onClick={handleNegar}
                            >
                              NEGAR
                            </Button>
                          </Col>
                          <Col sm={12} xs={12}>
                            <Button
                              variant="primary"
                              type="button"
                              className="buttonAuthorize"
                              onClick={handleAuthorize}
                            >
                              Autorizar
                            </Button>
                          </Col>
                        </>
                      )}
                  </Form>
                </Modal.Body>
              </Modal>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default ControlMovement;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledTable = styled(Table)`
  margin-top: 25px;
`;

const StyledFontAwesomeIconSignature = styled(FontAwesomeIcon)`
  color: ${(props) => {
    switch (props.status) {
      case "normal":
        return "gray";
      case "autorizado":
        return "green";
      case "negado":
        return "red";
      default:
        return "gray";
    }
  }};
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;
