import React from "react";
import {
    Container,
    Col,
    Card,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import "./styles.css";

const ReportProduct = () => {
    return (
        <>
            <Header />
            <div>
                <SidebarInfra />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>Dashboard de Refeição</CardUser>
                            <SpanSubtitle>
                                Veja o dashboard dos seus produtos
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <Col xs={12} className="controlForm">
                        <Container>
                            <iframe
                                title="Report Section"
                                src="https://app.powerbi.com/view?r=eyJrIjoiNDk4NmMyMDMtMjgzMy00ZThmLWIxZmUtMmE1ZTk1OWMyNDMwIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9"
                                frameborder="0"
                                allowFullScreen="true"
                                className="dashboard-products"
                            ></iframe>
                        </Container>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default ReportProduct;

const MainContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
`;

const CardUser = styled.div`
    font-family: Helvetica Neue Medium Extended;
    font-size: 28px;
    font-weight: 5600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Medium Extended;
    font-size: 18px;
    color: white;
`;
