import React from "react";
import {
  Container,
  Col,
  Card,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment/index";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { userAllowed } from "../../configs/userAllowed";

const ReportWasteDashboard = () => {
  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div>
        <SidebarInfraEnvironment />
        <MainContainer style={{ height: "230vh !important" }}>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Dashboard de Movimentação de Resíduos</CardUser>
              <SpanSubtitle>
                Veja o dashboard dos registros de movimentações de resíduos
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              <iframe
                title="Registro_Movimentações_Infra"
                src="https://app.powerbi.com/view?r=eyJrIjoiMjNlNmYxMTgtMTUyZS00MDI1LTg1OGItODJlOTgwZmNmODcyIiwidCI6ImJhMzg5NTgyLTA0M2ItNDBhYS04ZWJlLWNmYjBhZTI4MjgzMyJ9"
                frameborder="0"
                allowFullScreen="true"
                className="dashboard-products"
              ></iframe>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default ReportWasteDashboard;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;
