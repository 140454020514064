import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBurger, faSeedling } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { userGateway } from "../../configs/userGateway";
import "./styles.css";

function BotaoHome({ clickBtn, icon, textBtn, textSpan, port }) {

    return (
        <Col sm={6} xs={12} className="cards-position">
            <Card className="cardsGateway">
                <ButtonHome onClick={clickBtn}>
                    <StyledFontAwesome icon={icon} />
                    <TextButton>{textBtn}</TextButton>
                    <SpanDescription>{textSpan}</SpanDescription>
                </ButtonHome>
            </Card>
        </Col>
    );
}

function handleClickRestaurant() {
    if (userGateway(["todos", "restaurante"])) {
        window.location.href = "/home";
    } else {
        alert("Não permitido esse módulo para o seu usuário");
    }
}

function handleClickEnvironment() {
    if (userGateway(["todos", "ambiente"])) {
        window.location.href = "/home-environment";
    } else {
        alert("Não permitido esse módulo para o seu usuário");
    }
}

const Gateway = () => {
    const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));

    const homeBtns = [
        {
            clickBtn: handleClickRestaurant,
            icon: faBurger,
            textBtn: "Restaurante",
            textSpan: "Navegue pelo portal de restaurente",
            // tipos: ["Administrador"],
            port: ["todos", "restaurante"],
        },
        {
            clickBtn: handleClickEnvironment,
            icon: faSeedling,
            textBtn: "Meio ambiente",
            textSpan: "Navegue pelo portal de meio ambiente",
            // tipos: ["Administrador"],
            port: ["todos", "ambiente"],
        },
    ];

    return (
        <>
            <div style={{ display: "flex" }}>
                <MainContainer>
                    <Col
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Card className="cardsDadosGateway">
                            {usuarioLogado && (
                                <p className="name">
                                    Bem vindo,{" "}
                                    {usuarioLogado.name.split(" ")[0] + "!"}
                                </p>
                            )}
                            <p
                                className="date type-gateway"
                                style={{
                                    textTransform: "uppercase",
                                }}
                            >
                                Escolha o portal que deseja acessar
                            </p>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Row className="rowCards">
                            <CardsHome>
                                <Col xs={12} className="styledButtonsHome">
                                    <Row>
                                        {homeBtns.map((btn) => (
                                            <BotaoHome {...btn} />
                                        ))}
                                    </Row>
                                </Col>
                            </CardsHome>
                        </Row>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default Gateway;

const MainContainer = styled(Container)`
    display: flex;
    padding: 0rem 3rem;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        height: 100%;
        margin-top: 40px;
    }
`;

const CardsHome = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin: 10px 0px 10px 50px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
`;

const ButtonHome = styled.button`
    width: 20rem;
    border: none;
    height: 100vh;
    color: rgb(48, 151, 143);
    background-color: transparent;
    @media screen and (max-width: 768px) {
        width: 10rem;
    }
`;

const TextButton = styled.span`
    font-family: "Helvetica Neue Medium Extended";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    display: flex;
    align-content: center;
    justify-content: center;
`;

const SpanDescription = styled.span`
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 13px;
    font-family: "Helvetica Neue Medium Extended";
    font-style: normal;
    color: gray;
    padding: 10px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    height: 35px;
    padding: 20px;
`;
