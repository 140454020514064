import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faRecycle,
    faFileInvoice,
    faFileCirclePlus,
    faListCheck,
    faClipboardCheck,
    faBarChart,
    faChartLine,
    faTicket
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { userAllowed } from "../../configs/userAllowed";
import formatDate from "../../utils/functions/formatDate";

function BotaoHome({ clickBtn, icon, textBtn, textSpan, tipos }) {
    if (!userAllowed(tipos)) return;

    return (
        <Col sm={6} xs={12} className="cards-position">
            <Card className="cardsHome">
                <ButtonHome onClick={clickBtn}>
                    <StyledFontAwesome icon={icon} />
                    <TextButton>{textBtn}</TextButton>
                    <SpanDescription>{textSpan}</SpanDescription>
                </ButtonHome>
            </Card>
        </Col>
    );
}

const HomeEnvironment = () => {
    const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));

    function handleClickRegisterleavings() {
        window.location.href = "/register-leavings";
    }

    function handleClickMovementWaste() {
        window.location.href = "/movement-waste";
    }

    function handleClickControlMovement() {
        window.location.href = "/control-movement";
    }

    function handleClickConferenceWaste() {
        window.location.href = "/conference-waste";
    }

    function handleClickConferenceTicket() {
        window.location.href = "/conference-ticket";
    }

    function handleClickWasteFinished() {
        window.location.href = "/finished-list-waste";
    }

    function handleClickReport() {
        window.location.href = "/report-waste";
    }

    function handleDashoardReport() {
        window.location.href = "/report-waste-dashboard"
    }

    const homeBtns = [
        {
            clickBtn: handleClickRegisterleavings,
            icon: faFileCirclePlus,
            textBtn: "Cadastrar/Alterar Resíduos",
            textSpan: "Cadastre e altere seus resíduos",
            tipos: ["Administrador"],
        },
        {
            clickBtn: handleClickMovementWaste,
            icon: faRecycle,
            textBtn: "Emitir MID",
            textSpan: "Controle do gerenciamento dos resíduos internamente",
            tipos: ["Administrador", "Aprovador", "Requisitante"],
        },
        {
            clickBtn: handleClickControlMovement,
            icon: faFileInvoice,
            textBtn: "Controle de Movimentação de Resíduos",
            textSpan: "Registro de controle de movimentação de resíduos",
            tipos: ["Administrador", "Aprovador"],
        },
        {
            clickBtn: handleClickConferenceWaste,
            icon: faListCheck,
            textBtn: "Conferência do material na CMD ",
            textSpan: "Informações e conferência do material na área da CMD",
            tipos: ["Administrador", "Aprovador"],
        },
        {
            clickBtn: handleClickConferenceTicket,
            icon: faTicket,
            textBtn: "Conferência do Ticket de Pesagem ",
            textSpan: "Registra se o ticket de pesagem confere com o descarte",
            tipos: ["Administrador", "Aprovador"],
        },
        {
            clickBtn: handleClickWasteFinished,
            icon: faClipboardCheck,
            textBtn: "Descartes Finalizados",
            textSpan: "Veja todos os seus descartes finalizados",
            tipos: ["Administrador", "Aprovador"],
        },
        {
            clickBtn: handleClickReport,
            icon: faBarChart,
            textBtn: "Relatório do Resíduos",
            textSpan: "Veja o seu relatório por resíduos",
            tipos: ["Administrador"],
        },
        {
            clickBtn: handleDashoardReport,
            icon: faChartLine,
            textBtn: "Relatório",
            textSpan: "Veja o dashboard do seu relatório de resíduos",
            tipos: ["Administrador"],
        },
    ];

    return (
        <>
            <Header />
            <div style={{ display: "flex" }}>
                <SidebarInfraEnvironment />
                <MainContainer>
                    <Col
                        xs={12}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Card className="cardDados">
                            {usuarioLogado && (
                                <p className="name">
                                    {formatDate(new Date()).saudacao +
                                        ", " +
                                        usuarioLogado.name.split(" ")[0] +
                                        "!"}
                                </p>
                            )}
                            <p className="date">
                                {formatDate(new Date()).data}
                            </p>
                        </Card>
                    </Col>
                    <Col xs={12}>
                        <Row className="rowCards">
                            <CardsHome>
                                <Col xs={12} className="styledButtonsHome">
                                    <Row>
                                        {homeBtns.map((btn) => (
                                            <BotaoHome {...btn} />
                                        ))}
                                    </Row>
                                </Col>
                            </CardsHome>
                        </Row>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default HomeEnvironment;

const MainContainer = styled(Container)`
    display: flex;
    padding: 0rem 3rem;
    align-items: flex-start;
    flex-direction: column;
    @media screen and (max-width: 768px) {
        height: 100%;
        margin-top: 40px;
    }
`;

const CardsHome = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    margin: 10px 0px 10px 50px;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
`;

const ButtonHome = styled.button`
    width: 20rem;
    border: none;
    height: 100vh;
    color: rgb(48, 151, 143);
    background-color: transparent;
    @media screen and (max-width: 768px) {
        width: 10rem;
    }
`;

const TextButton = styled.span`
    font-family: "Helvetica Neue Medium Extended";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 16px;
    display: flex;
    align-content: center;
    justify-content: center;
`;

const SpanDescription = styled.span`
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 13px;
    font-family: "Helvetica Neue Medium Extended";
    font-style: normal;
    color: gray;
    padding: 10px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    height: 35px;
    padding: 20px;
`;
