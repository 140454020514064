import React, { useEffect, useState } from "react";
import {
    Container,
    Card,
    Col,
    Form,
    Row,
    Button,
    Alert,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";

function getDateTime(date = new Date()) {
    const y = date.getFullYear().toString().padStart(4, "0");
    const m = (date.getMonth() + 1).toString().padStart(2, "0");
    const d = date.getDate().toString().padStart(2, "0");
    const h = date.getHours().toString().padStart(2, "0");
    const mn = date.getMinutes().toString().padStart(2, "0");
    const s = date.getSeconds().toString().padStart(2, "0");

    return `${y}-${m}-${d} ${h}:${mn}:${s}`;
}

const RequestService = () => {
    const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));
    const [max, setMax] = useState(0);
    const [nome, setNome] = useState(usuarioLogado.name);
    const [unit, setUnit] = useState(usuarioLogado.unit);
    const [registration, setRegistration] = useState(max || 0);
    const [sector, setSector] = useState(usuarioLogado.sector);
    const [obs, setObs] = useState("");
    const [datetime, setDatetime] = useState(new Date());
    const [amount, setAmount] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [productType, setProductType] = useState("");
    const [costCenter, setCostCenter] = useState("");
    const [count, setCount] = useState("");
    const [local_delivery, setLocalDelivery] = useState("");
    const [date_delivery, setDateDelivery] = useState(new Date());
    const [productsList, setProductsList] = useState([]);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        async function fetchProducts() {
            try {
                const productList = await Requests.getProductsList();
                setProductsList(productList);
            } catch (error) {
                console.error("Erro ao carregar a lista de produtos", error);
            }
        }

        fetchProducts();
    }, []);

    const handleSelectChange = (event) => {
        setProductType(event.target.value);
    };

    const handleSelectCount = (event) => {
        setCount(event.target.value);
    };

    const handleSelectChangeUnit = (event) => {
        setUnit(event.target.value);
    };

    async function funcGetMaxRegistration() {
        const maxRegistration = await Requests.getLastRegistrationNumber();
        console.log(maxRegistration);
        setMax(maxRegistration);
        setRegistration(maxRegistration + 1);
    }
    useEffect(() => {
        funcGetMaxRegistration();
    }, []);

    const handleSubmit = (e) => {
        setDisabled(true);
        console.clear();
        e.preventDefault();
        const obj = {
            unit: unit,
            registration: registration,
            date: datetime,
            products: productType,
            amount: amount,
            observation: obs,
            name: nome,
            sector: sector,
            email: usuarioLogado.email,
            cost_center: costCenter,
            count: count,
            local_delivery: local_delivery,
            date_delivery: date_delivery
        };
        Requests.createRequest(obj)
            .then((res) => {
                if (res) {
                    console.log(res);
                    if (res.invalido) {
                        alert("Erro ao fazer requisição");
                    } else {
                        if (res) {
                            setSuccessMessage(true);
                            setTimeout(() => {
                                window.location.href = "/home";
                            }, 750);
                        }
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally(() => {
                setDisabled(false);
            });
    };

    return (
        <>
            <Header />
            <div style={{ display: "flex" }}>
                <SidebarInfra />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>SPOT - Requisição de Serviços</CardUser>
                            <SpanSubtitle>
                                Faça uma requisição de serviços
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <Col xs={12} className="controlForm">
                        <Container>
                            {successMessage && (
                                <Alert
                                    variant="success"
                                    onClose={() => setSuccessMessage(false)}
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon
                                        icon={faCircleCheck}
                                    />
                                    Requisição feita com sucesso!
                                </Alert>
                            )}
                            <Form onSubmit={handleSubmit}>
                                <DivForm>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicUnit">
                                                <Form.Label className="labelControl">
                                                    Unidade:
                                                </Form.Label>
                                                <Form.Select
                                                    value={unit}
                                                    onChange={
                                                        handleSelectChangeUnit
                                                    }
                                                    aria-label="Selecione um tipo"
                                                    style={{ height: "45px" }}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Selecione a unidade
                                                    </option>
                                                    <option value="Araxá">
                                                        Araxá
                                                    </option>
                                                    <option value="Patrocinio">
                                                        Patrocínio
                                                    </option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicSector">
                                                <Form.Label className="labelControl">
                                                    Setor:
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled
                                                    required
                                                    value={sector}
                                                    onChange={(event) =>
                                                        setSector(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicDate">
                                                <Form.Label className="labelControl">
                                                    Data:
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={getDateTime(
                                                        datetime
                                                    ).substring(0, 10)}
                                                    required
                                                    onChange={(event) => {
                                                        const dateValues =
                                                            event.target.value
                                                                .split("-")
                                                                .map((v) =>
                                                                    Number(v)
                                                                );
                                                        setDatetime((dt) => {
                                                            let newDate =
                                                                new Date(dt);
                                                            newDate.setFullYear(
                                                                dateValues[0],
                                                                dateValues[1] -
                                                                1,
                                                                dateValues[2]
                                                            );
                                                            return newDate;
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicTime">
                                                <Form.Label className="labelControl">
                                                    Horário:
                                                </Form.Label>
                                                <Form.Control
                                                    type="time"
                                                    value={getDateTime(
                                                        datetime
                                                    ).substring(11, 19)}
                                                    required
                                                    onChange={(event) => {
                                                        const timeValues =
                                                            event.target.value
                                                                .split(":")
                                                                .map((v) =>
                                                                    Number(v)
                                                                );
                                                        setDatetime((dt) => {
                                                            let newDate =
                                                                new Date(dt);
                                                            newDate.setHours(
                                                                timeValues[0],
                                                                timeValues[1],
                                                                timeValues[2]
                                                            );
                                                            return newDate;
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group
                                                controlId="formBasicDate"
                                            >
                                                <Form.Label className="labelControl">
                                                    Requisição:
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={registration}
                                                    disabled
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicProducts">
                                                <Form.Label className="labelControl">Produto:</Form.Label>
                                                <Form.Select
                                                    value={productType}
                                                    onChange={handleSelectChange}
                                                    aria-label="Selecione um tipo"
                                                    style={{ height: "45px" }}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Selecione um produto
                                                    </option>
                                                    {productsList.map((product) => (
                                                        <option key={product.id} value={product.name}>
                                                            {product.name}
                                                        </option>
                                                    ))}
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicAmount">
                                                <Form.Label className="labelControl mr-2">
                                                    Quantidade:
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Informe a quantidade desejada"
                                                    required
                                                    value={amount}
                                                    onChange={(event) =>
                                                        setAmount(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Nome:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    disabled
                                                    required
                                                    value={nome}
                                                    onChange={(event) =>
                                                        setNome(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicObs">
                                                <Form.Label className="mr-2">
                                                    Observação:
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Alguma observação?"
                                                    value={obs}
                                                    onChange={(event) =>
                                                        setObs(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>
                                                    Centro de custo:
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Informe o centro de custo"
                                                    value={costCenter}
                                                    onChange={(event) =>
                                                        setCostCenter(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicUnit">
                                                <Form.Label className="labelControl">
                                                    Conta razão:
                                                </Form.Label>
                                                <Form.Select
                                                    value={count}
                                                    onChange={handleSelectCount}
                                                    aria-label="Selecione a conta razão"
                                                    style={{ height: "45px" }}
                                                    required
                                                >
                                                    <option value="" disabled>
                                                        Selecione a conta razão
                                                    </option>
                                                    <option value="504315">
                                                        Araxá e Patrocínio
                                                        (504315)
                                                    </option>
                                                    <option value="505800">
                                                        Terceiro (505800)
                                                    </option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicName">
                                                <Form.Label>Local de entrega:</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    required
                                                    value={local_delivery}
                                                    onChange={(event) =>
                                                        setLocalDelivery(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicDate">
                                                <Form.Label className="labelControl">
                                                    Data de entrega:
                                                </Form.Label>
                                                <Form.Control
                                                    type="date"
                                                    value={getDateTime(
                                                        date_delivery
                                                    ).substring(0, 10)}
                                                    required
                                                    onChange={(event) => {
                                                        const dateValues =
                                                            event.target.value
                                                                .split("-")
                                                                .map((v) =>
                                                                    Number(v)
                                                                );
                                                        setDateDelivery((dt) => {
                                                            let newDate =
                                                                new Date(dt);
                                                            newDate.setFullYear(
                                                                dateValues[0],
                                                                dateValues[1] -
                                                                1,
                                                                dateValues[2]
                                                            );
                                                            return newDate;
                                                        });
                                                    }}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </DivForm>
                                <Col xs={12}>
                                    <Col sm={6} xs={12} className="buttonForm">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="buttonSolicitar"
                                            disabled={disabled}
                                        >
                                            Solicitar
                                        </Button>
                                    </Col>
                                </Col>
                            </Form>
                        </Container>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default RequestService;

const MainContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
`;

const CardUser = styled.div`
    font-family: Helvetica Neue Medium Extended;
    font-size: 28px;
    font-weight: 5600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Medium Extended;
    font-size: 18px;
    color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #00544d;
    margin-right: 10px;
`;

const DivForm = styled.div`
    border: 1px solid #dcdcdc;
    padding: 20px;
    border-radius: 10px;
    background-color: #ffffff9e;
`;
