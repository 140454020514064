import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import mosaic from "../../../assets/mosaic.png";
import Requests from "../../../configs/requets/index";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser} from "@fortawesome/free-solid-svg-icons";
import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";

const Header = ({ token }) => {
    const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));
    const navigate = useNavigate();
    const [profileOpen, setProfileOpen] = useState(false);
    const [editPass, setEditPass] = useState(false);
    const [senhaAntiga, setSenhaAntiga] = useState("");
    const [novaSenha, setNovaSenha] = useState("");

    const ProfilePic = (user) => {
        if (user.name) {
            let splited = user.name.split(" ");
            if (splited.length >= 2) {
                return (
                    splited[0].charAt(0).toUpperCase() +
                    splited[splited.length - 1].charAt(0).toUpperCase()
                );
            } else {
                return user.name.charAt(0).toUpperCase();
            }
        } else {
            return "";
        }
    };

    const handleSubmit = (e) => {
        console.clear();
        e.preventDefault();
        const obj = {
            id_user: usuarioLogado.id_user,
            password: senhaAntiga,
            newPassword: novaSenha,
        };
        let config = { headers: { "x-access-token": token } };

        Requests.changePass(obj, config)
            .then((res) => {
                if (res) {
                    console.log(res);
                    if (res.invalido) {
                        alert("Não foi possivel alterar sua senha!");
                    } else {
                        if (res.id_user) {
                            alert("Senha alterada com sucesso!");
                            setTimeout(() => {
                                console.clear();
                                window.location.reload();
                            }, 750);
                        }
                    }
                }
            })
            .catch((res, err) => {
                console.log(res, err);
            });
    };

    return (
        <>
            <Col xs={11} className="header">
                <HeaderTeste>
                    <Row
                        style={{
                            marginRight: 0,
                        }}
                    >
                        <Col>
                        </Col>
                        <Col
                            style={{
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            <InfoUsuario
                                onClick={() => {
                                    setProfileOpen(!profileOpen);
                                    setEditPass(false);
                                }}
                            >
                                <IconePerfil>
                                    <p>{ProfilePic(usuarioLogado)}</p>
                                </IconePerfil>

                                <PersonIconDiv>
                                    <FontAwesomeIcon
                                        icon={faUser}
                                        className="personIcon"
                                    />
                                </PersonIconDiv>

                                <NomeUsuario>
                                    <p className="nome">{usuarioLogado.name}</p>
                                    <p className="tipo">{usuarioLogado.type}</p>
                                </NomeUsuario>
                            </InfoUsuario>
                            {profileOpen && !editPass && (
                                <div className="divPerfil divPerfilDetalhe">
                                    <p>
                                        {usuarioLogado.email
                                            ? usuarioLogado.email
                                            : "email"}
                                    </p>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-around",
                                        }}
                                    >
                                        <p
                                            style={{
                                                margin: 0,
                                                marginLeft: "-25%",
                                            }}
                                            onClick={() => {
                                                sessionStorage.removeItem(
                                                    "token"
                                                );
                                                navigate("/");
                                            }}
                                        >
                                            Logout
                                        </p>
                                        <p
                                            style={{
                                                margin: 0,
                                                marginRight: "-25%",
                                            }}
                                            onClick={() => {
                                                setEditPass(true);
                                            }}
                                        >
                                            Alterar Senha
                                        </p>
                                    </div>
                                </div>
                            )}
                            {profileOpen && editPass && (
                                <div className="divPerfil divPerfilDetalhe">
                                    <form onSubmit={handleSubmit}>
                                        <p>Senha Antiga*</p>
                                        <input
                                            required
                                            id="senha-antiga"
                                            name="senha-antiga"
                                            value={senhaAntiga}
                                            onChange={(e) => {
                                                setSenhaAntiga(e.target.value);
                                            }}
                                            placeholder="Senha Antiga"
                                            type={"password"}
                                        ></input>
                                        <p>Nova Senha*</p>
                                        <input
                                            required
                                            id="nova-senha"
                                            name="nova-senha"
                                            value={novaSenha}
                                            onChange={(e) => {
                                                setNovaSenha(e.target.value);
                                            }}
                                            placeholder="Nova Senha"
                                            type={"password"}
                                        ></input>
                                        <button>Confirmar</button>
                                    </form>
                                </div>
                            )}
                            <img
                                src={mosaic}
                                className="foto logo-big"
                                alt="logo"
                            />
                        </Col>
                    </Row>
                </HeaderTeste>
            </Col>
        </>
    );
};

export default Header;

const HeaderTeste = styled.header`
    background-color: #fff;
    height: 72px;
    width: 100%;
    position: relative;
    z-index: 1;
    border-radius: 0px 0px 5px 5px;
    @media screen and (max-width: 768px) {
        display: none;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px),
        (min-width: 1024px) and (max-width: 1199px) {
        width: 97%;
    }
`;

export const InfoUsuario = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 50px;
    padding: 10px;
    cursor: pointer;
`;

export const IconePerfil = styled.div`
    padding: 5px;
    display: flex;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    font-size: small;
    height: 35px;
    width: 35px;
    background-color: rgb(48, 151, 143);
    color: #fff;
`;

export const PersonIconDiv = styled.div`
    background-color: rgb(255, 255, 255);
    margin-left: -15px;
    margin-top: 30px;
    width: fit-content;
    padding: 1px;
    height: 20px;
    width: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    .personIcon {
        color: rgb(48, 151, 143);
        height: 12px;
    }
`;

export const NomeUsuario = styled.div`
    margin: 0;
`;