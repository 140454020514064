import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Col,
  Row,
  Alert,
  Table,
  Form,
  Button,
  Modal,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faCircleCheck,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";

const RegistrationProducts = () => {
  const [products, setProducts] = useState([]);
  const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
  const [successMessageCreate, setSuccessMessageCreate] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [price, setPrice] = useState("");
  const [product, setProduct] = useState("");
  const [code, setCode] = useState("");
  const [unit, setUnit] = useState("Araxá");

  const handleCloseModal = () => setShowModal(false);
  const handleOpenModal = () => setShowModal(true);

  // const handleSelectChangeUnit = (event) => {
  //   const selectedUnit = event.target.value;
  //   setUnit(selectedUnit);
  //   fetchProducts(selectedUnit);
  // };

  const fetchProducts = async (selectedUnit) => {
    try {
      const response = await Requests.getProductsList();
      const updatedProducts = response
        .filter((product) => product.city === selectedUnit)
        .map((product) => ({
          ...product,
          isEditable: false,
        }));
      setProducts(updatedProducts);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchProducts("Araxá");
    // eslint-disable-next-line
  }, []);

  const cadasterProducts = async (event) => {
    try {
      const newProduct = {
        code: code,
        name: product,
        price: price,
        city: unit,
      };

      const response = await Requests.createProduct(newProduct);
      setProducts([...products, response]);

      setCode("");
      setProduct("");
      setPrice("");
      setShowModal(false);
      setSuccessMessageCreate(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEdit = (id) => {
    const updatedProducts = products.map((product) => {
      if (product.id_product === id) {
        return {
          ...product,
          isEditable: true,
        };
      } else {
        return {
          ...product,
          isEditable: false,
        };
      }
    });
    setProducts(updatedProducts);
  };

  const handleSave = async (productId, name, price, code) => {
    try {
      const updatedProducts = products.map((product) => {
        if (product.id_product === productId) {
          return {
            ...product,
            isEditable: false,
            name: name,
            price: price,
            code: code,
          };
        } else {
          return product;
        }
      });

      await Requests.updateProduct(productId, { name, price, code });

      setProducts(updatedProducts);
      setSuccessMessageUpdate(true);
    } catch (error) {
      console.error(error);
    }
  };

  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex", minHeight: "100vh" }}>
        <SidebarInfra />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Cadastrar/Alterar Produtos</CardUser>
              <SpanSubtitle>Cadastre ou altere seus produtos</SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              {successMessageUpdate && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessageUpdate(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Produto alterado com sucesso!
                </Alert>
              )}
              {successMessageCreate && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessageCreate(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Produto criado com sucesso!
                </Alert>
              )}

              <Col xs={12} className="position-button-products mb-2">
                <Row>
                  <Col xs={12}>
                    <Button
                      variant="primary"
                      type="submit"
                      className="buttonProducts"
                      onClick={handleOpenModal}
                    >
                      <FontAwesomeIcon
                        icon={faCirclePlus}
                        style={{ marginRight: "10px" }}
                      />
                      Cadastrar produto
                    </Button>
                  </Col>
                </Row>
              </Col>
              {/* <Col sm={12} className="d-flex justify-content-end mb-4">
                <Col sm={6} xs={12}>
                  <Form.Group controlId="formBasicUnit">
                    <Form.Label className="labelControl">Unidade:</Form.Label>
                    <Form.Select
                      value={unit}
                      onChange={handleSelectChangeUnit}
                      aria-label="Selecione um tipo"
                      style={{ height: "45px" }}
                      required
                    >
                      <option value="" disabled>
                        Selecione a unidade
                      </option>
                      <option value="Araxá">Araxá</option>
                      <option value="Patrocinio">Patrocínio</option>
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Col> */}
              <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Cadastro de Produto</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Form>
                    <Col sm={12} xs={12}>
                      <Form.Group controlId="formBasicProduct">
                        <Form.Label className="labelControl">
                          Código do Produto:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o código do produto"
                          value={code}
                          onChange={(event) => setCode(event.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Form.Group controlId="formBasicProduct">
                        <Form.Label className="labelControl">Nome</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o nome do produto"
                          value={product}
                          onChange={(event) => setProduct(event.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Form.Group controlId="formBasicUnit">
                        <Form.Label className="labelControl">
                          Valor unitário
                        </Form.Label>
                        <Form.Control
                          type="number"
                          step="0.01"
                          min="0"
                          placeholder="Informe o valor unitário do produto"
                          value={price}
                          onChange={(event) =>
                            setPrice(parseFloat(event.target.value))
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={12} xs={12}>
                      <Form.Group controlId="formBasicUnit">
                        <Form.Label className="labelControl">
                          Unidade:
                        </Form.Label>
                        <Form.Select
                          value={unit}
                          aria-label="Selecione um tipo"
                          style={{
                            height: "45px",
                          }}
                          required
                          onChange={(event) => setUnit(event.target.value)}
                        >
                          <option value="" disabled>
                            Selecione a unidade
                          </option>
                          <option value="Araxá">Araxá</option>
                          <option value="Patrocinio">Patrocínio</option>
                        </Form.Select>
                      </Form.Group>
                    </Col>

                    <Button
                      className="buttonProducts"
                      variant="primary"
                      onClick={cadasterProducts}
                    >
                      Cadastrar
                    </Button>
                  </Form>
                </Modal.Body>
              </Modal>
              <Table striped bordered hover>
                <thead style={{ textAlign: "center" }}>
                  <tr>
                    <th>Código</th>
                    <th>Nome</th>
                    <th>Valor Unitário</th>
                    <th>Editar</th>
                  </tr>
                </thead>
                <tbody style={{ textAlign: "center" }}>
                  {products.map((product) => (
                    <tr key={product.id_product}>
                      <td>
                        {product.isEditable ? (
                          <Form.Control
                            type="text"
                            value={product.code}
                            onChange={(event) => {
                              const newCode = event.target.value;
                              setProducts((prevProducts) =>
                                prevProducts.map((prevProduct) =>
                                  prevProduct.id_product === product.id_product
                                    ? {
                                        ...prevProduct,
                                        code: newCode,
                                      }
                                    : prevProduct
                                )
                              );
                            }}
                          />
                        ) : (
                          product.code
                        )}
                      </td>
                      <td>
                        {product.isEditable ? (
                          <Form.Control
                            type="text"
                            value={product.name}
                            onChange={(event) => {
                              const newName = event.target.value;
                              setProducts((prevProducts) =>
                                prevProducts.map((prevProduct) =>
                                  prevProduct.id_product === product.id_product
                                    ? {
                                        ...prevProduct,
                                        name: newName,
                                      }
                                    : prevProduct
                                )
                              );
                            }}
                          />
                        ) : (
                          product.name
                        )}
                      </td>
                      <td>
                        {product.isEditable ? (
                          <Form.Control
                            type="number"
                            step="0.01"
                            min="0"
                            value={product.price}
                            onChange={(event) => {
                              const newPrice = parseFloat(event.target.value);
                              setProducts((prevProducts) =>
                                prevProducts.map((prevProduct) =>
                                  prevProduct.id_product === product.id_product
                                    ? {
                                        ...prevProduct,
                                        price: newPrice,
                                      }
                                    : prevProduct
                                )
                              );
                            }}
                          />
                        ) : (
                          new Intl.NumberFormat("pt-BR", {
                            style: "currency",
                            currency: "BRL",
                          }).format(product.price)
                        )}
                      </td>
                      <td>
                        {product.isEditable ? (
                          <Button
                            variant="success"
                            onClick={() => {
                              handleSave(
                                product.id_product,
                                product.name,
                                product.price,
                                product.code
                              );
                            }}
                          >
                            Salvar
                          </Button>
                        ) : (
                          <StyledButton
                            variant="primary"
                            onClick={() => {
                              handleEdit(product.id_product);
                            }}
                          >
                            <StyledFontAwesome icon={faEdit} />
                          </StyledButton>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default RegistrationProducts;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
  color: #fff;
`;

const StyledButton = styled(Button)`
  background-color: rgb(48, 151, 143);
  border: none;
  color: #fff;
  &:hover {
    background-color: rgb(0, 84, 77);
  }
`;
