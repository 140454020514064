import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import BaseLogin from "./pages/baseLogin";
import Gateway from "./pages/gateway";
// Restaurante
import Home from "./pages/home";
import ControlDelivery from "./pages/controlDelivery";
import UserRegister from "./pages/registerUser";
import RequestService from "./pages/requestService";
import RequestSignature from "./pages/requestSignature";
import Report from "./pages/reportBM";
import RegistrationProducts from "./pages/registerProducts";
import Tablita from "./pages/tablita";
import ReportProduct from "./pages/reportProduct";
import ReportSignature from "./pages/reportSignature";
// Meio-ambiente
import HomeEnvironment from "./pages/homeEnvironment";
import RegistrationLeavings from "./pages/registerLeavings";
import MovementWaste from "./pages/MID";
import ControlMovement from "./pages/controlMovement";
import ConferenceResidue from "./pages/conferenceResidue";
import WasteFinished from "./pages/wasteFinished";
import ReportWaste from "./pages/reportWaste";
import ReportWasteDashboard from "./pages/reportDashWaste";
import ConferenceTicket from "./pages/conferenceTicket";

function App() {
  function redirecTo(path) {
    window.location.href = path;
  }

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" exact element={<BaseLogin redirecTo={redirecTo} />} />
          {/* Administrador */}
          <Route path="/type-gateway" element={<Gateway />} />

          {/* Meio-ambiente */}
          <Route path="/home-environment" element={<HomeEnvironment />} />
          <Route path="/register-leavings" element={<RegistrationLeavings />} />
          <Route path="/movement-waste" element={<MovementWaste />} />
          <Route path="/control-movement" element={<ControlMovement />} />
          <Route path="/conference-waste" element={<ConferenceResidue />} />
          <Route path="/conference-ticket" element={<ConferenceTicket />} />
          <Route path="/finished-list-waste" element={<WasteFinished />} />
          <Route path="/report-waste" element={<ReportWaste />} />
          <Route path="/report-waste-dashboard" element={<ReportWasteDashboard />} />

          {/* Restaurante */}
          <Route path="/home" element={<Home />} />
          <Route path="/control-delivery" element={<ControlDelivery />} />
          <Route path="/user-register" element={<UserRegister />} />
          <Route path="/request-service" element={<RequestService />} />
          <Route path="/request-signature" element={<RequestSignature />} />
          <Route path="/report" element={<Report />} />
          <Route
            path="/registration-products"
            element={<RegistrationProducts />}
          />
          <Route path="/report-product" element={<ReportProduct />} />
          <Route path="/tablita" element={<Tablita />} />
          <Route path="/report-signature" element={<ReportSignature />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
