import React, { useState, useEffect } from "react";
import {
    Container,
    Card,
    Alert,
    Table,
    Button,
    Col,
    Form,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCircleCheck } from "@fortawesome/free-solid-svg-icons";

const Tablita = () => {
    const [successMessage, setSuccessMessage] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [tablita, setTablita] = useState([]);
    const [unit, setUnit] = useState("Araxá");

    const handleSelectChangeUnit = (event) => {
        const selectedUnit = event.target.value;
        setUnit(selectedUnit);
        fetchTablita(selectedUnit);
    };

    const fetchTablita = async (selectedUnit) => {
        try {
            const response = await Requests.getTablitaList();
            console.log(response)
            const updatedTablita = response
                .filter((tb) => tb.city === selectedUnit)
                .map((tablita) => ({
                    ...tablita,
                    isEditable: false,
                }));

            setTablita(updatedTablita);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchTablita("Araxá");
        // eslint-disable-next-line
    }, []);

    const headers = ["Cidade", "Quantidade", "Preço Unitário", ""];

    const handleEditClick = async (index) => {
        const updatedTablita = [...tablita];
        updatedTablita[index].isEditable = !updatedTablita[index].isEditable;

        if (!updatedTablita[index].isEditable) {
            const config = {};
            const data = {
                id_tablePrice: updatedTablita[index].id,
                amount: updatedTablita[index].amount,
                price: updatedTablita[index].price,
                city: updatedTablita[index].city,
            };
            try {
                await Requests.updateTablita(
                    updatedTablita[index].id_tablePrice,
                    data,
                    config
                );
                sessionStorage.setItem(
                    "tablitaData",
                    JSON.stringify(updatedTablita)
                );
                setSuccessMessage(true);
            } catch (error) {
                console.error(error);
            }
        }

        setTablita(updatedTablita);
    };

    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const updatedTablita = [...tablita];
        updatedTablita[index][name] = value;
        setTablita(updatedTablita);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const config = {};
            const updatedData = tablita.map((row) => ({
                id: row.id,
                amount: row.amount,
                price: row.price,
            }));
            await Promise.all(
                updatedData.map((data, index) =>
                    Requests.updateTablita(tablita[index].id, data, config)
                )
            );
            setSuccessMessage(true);
            setEditMode(false);
            sessionStorage.setItem("tablitaData", JSON.stringify(tablita));
        } catch (error) {
            console.error(error);
        }
    };

    const renderTable = () => {
        return tablita.map((row, index) => (
            <tr key={index}>
                <td>{row.city}</td>
                <td>
                    {row.isEditable ? (
                        <StyledInput
                            type="text"
                            name="amount"
                            value={row.amount}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    ) : (
                        row.amount
                    )}
                </td>
                <td>
                    {row.isEditable ? (
                        <StyledInput
                            type="number"
                            name="price"
                            step="0.01"
                            min="0"
                            value={row.price}
                            onChange={(e) => handleInputChange(e, index)}
                        />
                    ) : (
                        row.price
                    )}
                </td>
                <td>
                    <StyledButton onClick={() => handleEditClick(index)}>
                        {row.isEditable ? (
                            <StyledFontAwesomeIcon icon={faCircleCheck} />
                        ) : (
                            <StyledFontAwesomeIcon icon={faEdit} />
                        )}
                    </StyledButton>
                </td>
            </tr>
        ));
    };

    if (!userAllowed(["Administrador"])) {
        window.location.href = "/home";
        alert("Não permitido para o seu usuário");
        return <></>;
    }

    return (
        <>
            <Header />
            <div style={{ display: "flex", minHeight: "100vh" }}>
                <SidebarInfra />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>Tablita</CardUser>
                            <SpanSubtitle>
                                Veja e altere sua tablita
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <StyledContainer>
                        {successMessage && (
                            <Alert
                                variant="success"
                                onClose={() => setSuccessMessage(false)}
                                dismissible
                            >
                                Dados editados com sucesso!
                            </Alert>
                        )}
                        <Col sm={12} className="d-flex justify-content-end mb-4" >
                            <Col sm={6} xs={12}>
                                <Form.Group controlId="formBasicUnit">
                                    <Form.Label className="labelControl">
                                        Unidade:
                                    </Form.Label>
                                    <Form.Select
                                        value={unit}
                                        onChange={handleSelectChangeUnit}
                                        aria-label="Selecione um tipo"
                                        style={{ height: "45px" }}
                                        required
                                    >
                                        <option value="" disabled>
                                            Selecione a unidade
                                        </option>
                                        <option value="Araxá">Araxá</option>
                                        <option value="Patrocinio">
                                            Patrocínio
                                        </option>
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Col>
                        <Table striped bordered hover>
                            <thead style={{ textAlign: "center" }}>
                                <tr>
                                    {headers.map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody style={{ textAlign: "center" }}>
                                {renderTable()}
                            </tbody>
                        </Table>
                        {editMode && (
                            <div style={{ textAlign: "center" }}>
                                <StyledButton
                                    variant="success"
                                    type="submit"
                                    onClick={handleSubmit}
                                >
                                    Salvar
                                </StyledButton>
                            </div>
                        )}
                    </StyledContainer>
                </MainContainer>
            </div>
        </>
    );
};

export default Tablita;

const StyledContainer = styled(Container)`
    padding-left: 110px;
    padding-top: 20px;
    @media screen and (max-width: 768px) {
        padding-left: 0;
        padding-top: 0;
    }
`;

const MainContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
`;

const CardUser = styled.div`
    font-family: Helvetica Neue Medium Extended;
    font-size: 28px;
    font-weight: 5600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Medium Extended;
    font-size: 18px;
    color: white;
`;

const StyledInput = styled.input`
    width: 100%;
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &:focus {
        outline: 0;
        border-color: #80bdff;
        box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }
`;

const StyledButton = styled(Button)`
    background-color: rgb(48, 151, 143);
    border: none;
    color: #fff;
    &:hover {
        background-color: rgb(0, 84, 77);
    }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    font-size: 20px;
`;