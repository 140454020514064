import React, { useState, useEffect } from "react";
import {
  Container,
  Col,
  Form,
  Row,
  Button,
  Card,
  Table,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment/index";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { parseISO, startOfDay, endOfDay } from "date-fns";

const ReportWaste = () => {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  const [dateEnd, setDateEnd] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [residue, setResidue] = useState("");
  const [residueList, setResidueList] = useState([]);
  const [reportData, setReportData] = useState(null);
  const [totalInput, setTotalInput] = useState(0);
  const [totalOutput, setTotalOutput] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Requests.getLeavingsList();
        setResidueList(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (event) => {
    setResidue(event.target.value);
  };

  useEffect(() => {
    const fetchReportData = async () => {
      try {
        const response = await Requests.getReportWastes(date, dateEnd);
        setReportData(response.reports);
      } catch (error) {
        console.error(error);
      }
    };

    fetchReportData();
  }, [date, dateEnd]);

  useEffect(() => {
    if (reportData && reportData.length > 0) {
      const inputsSum = reportData.reduce(
        (total, report) => total + parseFloat(report.net_weight),
        0
      );
      setTotalInput(inputsSum);

      const outputsSum = reportData.reduce(
        (total, report) => total + parseFloat(report.stock_exit),
        0
      );
      setTotalOutput(outputsSum);
    } else {
      setTotalInput(0);
      setTotalOutput(0);
    }
  }, [reportData]);

  const handleRequestReport = async () => {
    try {
      setLoading(true);
      const startDate = startOfDay(parseISO(date));
      const endDate = endOfDay(parseISO(dateEnd));
      const response = await Requests.getReportWastes(
        startDate,
        endDate,
        residue
      );
      setReportData(response.reports);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleEdit = (id) => {
    const updatedMovement = reportData.map((movement) => {
      if (movement.id_movement === id) {
        return {
          ...movement,
          isEditable: true,
        };
      } else {
        return {
          ...movement,
          isEditable: false,
        };
      }
    });
    setReportData(updatedMovement);
  };

  const handleSave = async (
    movementId,
    setor_generator,
    stock_exit,
    stored_location,
    destination_waste,
    mtr_waste,
    electronic_invoice,
    transport
  ) => {
    try {
      const updatedMovement = reportData.map((movement) => {
        if (movement.id_movement === movementId) {
          const final_stock =
            parseFloat(movement.initial_stock) +
            parseFloat(movement.net_weight) -
            parseFloat(stock_exit);

          return {
            ...movement,
            isEditable: false,
            setor_generator: setor_generator,
            stock_exit: stock_exit,
            stored_location: stored_location,
            destination_waste: destination_waste,
            mtr_waste: mtr_waste,
            electronic_invoice: electronic_invoice,
            transport: transport,
            final_stock: final_stock,
          };
        } else {
          return movement;
        }
      });

      await Requests.updateReport(movementId, {
        setor_generator,
        stock_exit,
        stored_location,
        destination_waste,
        mtr_waste,
        electronic_invoice,
        transport,
      });

      setReportData(updatedMovement);
      window.location.reload();
    } catch (error) {
      console.error(error);
    }
  };

  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div>
        <SidebarInfraEnvironment />
        <MainContainer style={{ height: "230vh !important" }}>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Registro de Movimentação de Resíduos</CardUser>
              <SpanSubtitle>
                Veja o relatório dos registro de movimentação de resíduos
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              <Form>
                <DivForm>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicCompany">
                        <Form.Label className="labelControl">
                          Período:
                        </Form.Label>
                        <Col sm={12} xs={12}>
                          <Row>
                            <Col sm={5} xs={6} className="mb-2">
                              <Form.Control
                                type="date"
                                value={date}
                                onChange={(event) =>
                                  setDate(event.target.value)
                                }
                              />
                            </Col>
                            <Col sm={1}>
                              <SpanText>Até:</SpanText>
                            </Col>
                            <Col sm={5} xs={6}>
                              <Form.Control
                                type="date"
                                value={dateEnd}
                                onChange={(event) =>
                                  setDateEnd(event.target.value)
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicProducts">
                        <Form.Label className="labelControl mr-2">
                          Resíduo
                        </Form.Label>
                        <Form.Select
                          value={residue}
                          onChange={handleSelectChange}
                          aria-label="Selecione um tipo"
                          style={{ height: "45px" }}
                          required
                        >
                          <option value="" disabled>
                            Selecione um resíduo
                          </option>
                          {residueList.map((residue) => (
                            <option
                              key={residue.id_leavings}
                              value={residue.name_leavings}
                            >
                              {residue.name_leavings}
                            </option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                </DivForm>
                <Col xs={12}>
                  <Col sm={6} xs={12} className="buttonForm mb-2">
                    <Button
                      variant="primary"
                      type="button"
                      className="buttonCadaster"
                      onClick={handleRequestReport}
                      disabled={loading}
                    >
                      {loading ? "Carregando..." : "Solicitar Relatório"}
                    </Button>
                  </Col>
                </Col>
              </Form>
            </Container>
          </Col>

          <Container>
            <ReportContainer>
              {loading && (
                <Container
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "5rem",
                  }}
                >
                  <div className="text-center mt-3">
                    <Spinner animation="border" role="status">
                      <span className="sr-only">Carregando...</span>
                    </Spinner>
                  </div>
                </Container>
              )}
              {reportData && reportData.length > 0 && (
                // <Container>
                <Col
                  xs={12}
                  sm={12}
                  className="table-movement table-conference-cmd"
                >
                  <h4 className="mb-3">Registros:</h4>
                  <TableReport striped bordered hover>
                    <thead>
                      <tr>
                        <Styledth>Data</Styledth>
                        <Styledth>Resíduo</Styledth>
                        <Styledth>Gerador/Origem</Styledth>
                        <Styledth>Estoque Inicial</Styledth>
                        <Styledth>Entrada</Styledth>
                        <Styledth>Saída</Styledth>
                        <Styledth>Estoque Final</Styledth>
                        <Styledth>Acondicionamento</Styledth>
                        <Styledth>Destinação Final</Styledth>
                        <Styledth>MTR Nº</Styledth>
                        <Styledth>N.F</Styledth>
                        <Styledth>Transportadora</Styledth>
                        <Styledth>Editar</Styledth>
                      </tr>
                    </thead>
                    <tbody>
                      {reportData.map((report) => (
                        <tr key={report.id_movement}>
                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {new Date(
                              report.date_solicitation
                            ).toLocaleDateString()}
                          </StyledTd>

                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {report.residue}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.setor_generator}
                                onChange={(event) => {
                                  const newSector = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          setor_generator: newSector,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.setor_generator
                            )}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {report.initial_stock.toFixed(2)}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.net_weight}
                                onChange={(event) => {
                                  const newNetWeight = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          net_weight: newNetWeight,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.net_weight
                            )}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.stock_exit}
                                onChange={(event) => {
                                  const newStockExit = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          stock_exit: newStockExit,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.stock_exit
                            )}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "end",
                            }}
                          >
                            {report.final_stock.toFixed(2)}
                          </StyledTd>

                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.stored_location}
                                onChange={(event) => {
                                  const newStoredLocation = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          stored_location: newStoredLocation,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.stored_location
                            )}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.destination_waste}
                                onChange={(event) => {
                                  const newDestionationWaste =
                                    event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          destination_waste:
                                            newDestionationWaste,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.destination_waste
                            )}
                          </StyledTd>

                          <StyledTd style={{ textAlign: "initial" }}>
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.mtr_waste || ""}
                                onChange={(event) => {
                                  const newMtrWaste = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          mtr_waste: newMtrWaste,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : report.mtr_waste !== null &&
                              report.mtr_waste !== "null" ? (
                              report.mtr_waste
                            ) : (
                              "-"
                            )}
                          </StyledTd>
                          <StyledTd style={{ textAlign: "initial" }}>
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.electronic_invoice || ""}
                                onChange={(event) => {
                                  const newElectronicInvoice =
                                    event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          electronic_invoice:
                                            newElectronicInvoice,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : report.electronic_invoice !== null &&
                              report.electronic_invoice !== "null" ? (
                              report.electronic_invoice
                            ) : (
                              "-"
                            )}
                          </StyledTd>
                          <StyledTd
                            style={{
                              textAlign: "initial",
                            }}
                          >
                            {report.isEditable ? (
                              <Form.Control
                                type="text"
                                value={report.transport}
                                onChange={(event) => {
                                  const newTransport = event.target.value;
                                  setReportData((prevMovements) =>
                                    prevMovements.map((prevMovement) =>
                                      prevMovement.id_movement ===
                                        report.id_movement
                                        ? {
                                          ...prevMovement,
                                          transport: newTransport,
                                        }
                                        : prevMovement
                                    )
                                  );
                                }}
                              />
                            ) : (
                              report.transport
                            )}
                          </StyledTd>
                          <StyledTd>
                            {report.isEditable ? (
                              <Button
                                variant="success"
                                onClick={() => {
                                  handleSave(
                                    report.id_movement,
                                    report.setor_generator,
                                    report.stock_exit,
                                    report.stored_location,
                                    report.destination_waste,
                                    report.mtr_waste,
                                    report.electronic_invoice,
                                    report.transport
                                  );
                                }}
                              >
                                Salvar
                              </Button>
                            ) : (
                              <StyledButton
                                variant="primary"
                                onClick={() => {
                                  handleEdit(report.id_movement);
                                }}
                              >
                                <StyledFontAwesome icon={faEdit} />
                              </StyledButton>
                            )}
                          </StyledTd>
                        </tr>
                      ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="4" style={{ fontWeight: "bold" }}>
                          Total entrada:
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontWeight: "bold",
                          }}
                        >
                          {totalInput.toFixed(2)}
                        </td>
                        <td colSpan="8"></td>
                      </tr>
                      <tr>
                        <td colSpan="5" style={{ fontWeight: "bold" }}>
                          Total saida:
                        </td>
                        <td
                          style={{
                            textAlign: "end",
                            fontWeight: "bold",
                          }}
                        >
                          {totalOutput.toFixed(2)}
                        </td>
                        <td colSpan="8"></td>
                      </tr>
                    </tfoot>
                  </TableReport>
                </Col>
                // </Container>
              )}
            </ReportContainer>
          </Container>
        </MainContainer>
      </div>
    </>
  );
};

export default ReportWaste;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const ReportContainer = styled(Col)`
  padding: 3.5rem;

  @media screen and (max-width: 768px) {
    padding: 0;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    padding: 1rem;
  }
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const SpanText = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  text-transform: uppercase;
  padding-left: 30px;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: block;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 0.5rem;
  }
`;

const TableReport = styled(Table)`
  width: 100%;
  border-collapse: collapse;
  font-size: 16px;
  text-align: center;
  margin-bottom: 3rem;
`;

const StyledButton = styled(Button)`
  background-color: rgb(48, 151, 143);
  border: none;
  color: #fff;
  &:hover {
    background-color: rgb(0, 84, 77);
  }
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
  color: #fff;
`;

const Styledth = styled.th`
  font-size: 13px;
  padding: 0.1rem !important;
`;

const StyledTd = styled.td`
  font-size: 12px;
`;
