import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Card,
  Col,
  Table,
  Modal,
  Form,
  Row,
  Button,
  Alert,
  Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import moment from "moment";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faQuestionCircle,
  faCircleXmark,
  faXmark
} from "@fortawesome/free-solid-svg-icons";

const ConferenceResidue = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [successMessageDelete, setSuccessMessageDelete] = useState(false);
  const [movementList, setMovementList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [currentItems, setCurrentItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 20;
  const [activeButton, setActiveButton] = useState("descarte");
  const [vehicle, setVehicle] = useState("");
  const [licensePlate, setLicensePlate] = useState("");
  const [motorist, setMotorist] = useState("");
  const [registrationMotorist, setRegistrationMotorist] = useState("");
  const [responsibleAcess, setResponsibleAcess] = useState("");
  const [registrationResponsible, setRegistrationResponsible] = useState("");
  const [taraVehicle, setTaraVehicle] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [uploadImageFile, setUploadImageFile] = useState(null);
   // const statusConferece = "finalizado";
  const statusConferece = "conferir ticket";
  const [disabled, setDisabled] = useState(false);
  const sigRef = useRef(null);

  const updateCurrentItems = () => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const updatedCurrentItems = movementList
      .slice(indexOfFirstItem, indexOfLastItem);
    setCurrentItems(updatedCurrentItems);
  };

  useEffect(() => {
    updateCurrentItems();
    // eslint-disable-next-line
  }, [currentPage, movementList]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setCurrentItems([]);
  };

  const fetchMovementList = async () => {
    try {
      const response = await Requests.getMovementListAguardando();

      response.sort((a, b) => {
        return (
          moment(b.date_solicitation).valueOf() -
          moment(a.date_solicitation).valueOf()
        );
      });

      setMovementList(response);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    setDisabled(true);
    console.clear();
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("id_movement", selectedRequest.id_movement);
      formData.append("vehicle_transport", vehicle);
      formData.append("license_plate", licensePlate);
      formData.append("name_motorist", motorist);
      formData.append("registration_motorist", registrationMotorist);
      formData.append("name_resposible", responsibleAcess);
      formData.append("registration_resposible", registrationResponsible);
      formData.append("status_conference", statusConferece);
      formData.append("tare_vehicle", taraVehicle);
      formData.append("gross_weight", grossWeight);
      formData.append("net_weight", netWeight);
      formData.append("image_conference", uploadImageFile);

      const res = await Requests.createConferenceWaste(
        selectedRequest.id_movement,
        formData
      );

      if (res && !res.invalido) {
        const updatedMovementList = movementList.map((request) => {
          if (request.id_movement === selectedRequest.id_movement) {
            return {
              ...request,
              ...formData,
            };
          }
          return request;
        });

        // setDisabled(true);
        setMovementList(updatedMovementList);
        setSuccessMessage(true);
        setTimeout(() => {
          window.location.href = "/home-environment";
        }, 750);
      } else {
        alert("Erro ao fazer conferrência");
      }
    } catch (error) {
      console.error(error);
      alert("Erro ao fazer conferrência");
    } finally {
      setDisabled(false);
    }
  };

  const handleImageUpload = (e) => {
    const imageFile = e.target.files[0];
    if (imageFile) {
      setUploadImageFile(imageFile);

      const imageUrl = URL.createObjectURL(imageFile);
      setImageFile(imageUrl);
    }
  };

  const handleRemoveImage = () => {
    // Limpar a prévia da imagem
    setImageFile(null);
    // Limpar o input do tipo file (opcional, para que o usuário possa selecionar a mesma imagem novamente)
    document.getElementById("uploadImage").value = "";
  };

  useEffect(() => {
    console.log(imageFile);
  }, [imageFile]);

  useEffect(() => {
    fetchMovementList();
    // eslint-disable-next-line
  }, []);

  const openModal = (request) => {
    setSelectedRequest(request);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  // const handleRadioOptionChange = (option) => {
  //   setRadioOption(option);
  // };

  const handleDelete = (movementId) => {
    if (window.confirm("Tem certeza de que deseja excluir este descarte?")) {

      Requests.deleteCMD(movementId)
        .then(() => {
          const uptatedMovement = movementList.filter((movement) => movement.id_movement !== movementId);
          setMovementList(uptatedMovement);
          setSuccessMessageDelete(true);
        })
        .catch((error) => {
          console.error("Erro ao excluir o resíduo:", error);
        });
    }
  };

  if (!userAllowed(["Administrador", "Aprovador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SidebarInfraEnvironment />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Conferência do material na CMD</CardUser>
              <SpanSubtitle>
                Faça a conferência do material na área do CMD
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm mobile-table-products">
            <Container>
              {successMessage && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessage(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Conferência feita com sucesso!
                </Alert>
              )}

              {successMessageDelete && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessageDelete(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Descarte excluído com sucesso!
                </Alert>
              )}

              {loading ? (
                <p>Carregando...</p>
              ) : (
                <>
                  <div className="table-movement table-conference-cmd">
                    <StyledTable striped bordered hover>
                      <thead>
                        <tr
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <th>Nº MID</th>
                          <th>Gerência</th>
                          <th>Complexo industrial</th>
                          <th>Representante</th>
                          <th>Setor gerador/Empresa contratada</th>
                          <th>Resíduo</th>
                          <th>Data</th>
                          <th>Conferência</th>
                          <th>Excluir</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentItems.map((request) => (
                          <tr
                            key={request.id_movement}
                            style={{
                              textAlign: "center",
                            }}
                            onClick={() => openModal(request)}
                          >
                            <td>{request.amount_residue}</td>
                            <td>{request.administration}</td>
                            <td>{request.industrial}</td>
                            <td>{request.representative}</td>
                            <td>{request.setor_generator}</td>
                            <td>{request.residue}</td>
                            <td>
                              {moment(request.date_solicitation).format(
                                "DD/MM/YYYY"
                              )}
                            </td>
                            <td>{request.status_conference}</td>
                            <td className="text-center">
                              <Button
                                variant="danger"
                                onClick={() => {
                                  handleDelete(request.id_movement);
                                }}
                              >
                                <FontAwesomeIcon icon={faXmark} />
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </StyledTable>
                  </div>
                  {movementList.length > itemsPerPage && (
                    <Pagination className="pagination-style">
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="button-pagination-pass"
                      >
                        {"<"}
                      </Button>
                      {Array.from({
                        length: Math.ceil(movementList.length / itemsPerPage),
                        // eslint-disable-next-line
                      }).map((_, index) => {
                        if (
                          index + 1 === currentPage ||
                          (index + 2 === currentPage &&
                            currentPage !==
                            Math.ceil(movementList.length / itemsPerPage)) ||
                          index === currentPage
                        ) {
                          return (
                            <Pagination.Item
                              className="button-pagination"
                              key={index}
                              active={index + 1 === currentPage}
                              onClick={() => handlePageChange(index + 1)}
                            >
                              {index + 1}
                            </Pagination.Item>
                          );
                        } else if (
                          (index === currentPage - 2 && index !== 0) ||
                          (index === currentPage + 1 &&
                            index !==
                            Math.ceil(movementList.length / itemsPerPage) - 1)
                        )
                          // {
                          //   return <Pagination.Ellipsis key={index} />;
                          // }
                          return null;
                      })}
                      <Button
                        variant="primary"
                        type="button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={
                          currentPage ===
                          Math.ceil(movementList.length / itemsPerPage)
                        }
                        className="button-pagination-pass"
                      >
                        {">"}
                      </Button>
                    </Pagination>
                  )}
                </>
              )}
              <Modal show={showModal} onHide={closeModal} className="modal-xl">
                <Modal.Header closeButton>
                  <Modal.Title>
                    Informações da Solicitação de Descarte e Conferência do
                    Material
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Col
                    xs={12}
                    className="d-flex justify-content-center align-center mb-3 options-buttons-waste"
                  >
                    <Row>
                      <ButtonsOptions
                        active={activeButton === "descarte"}
                        onClick={() => handleButtonClick("descarte")}
                        className="col-6"
                      >
                        Informações do descarte
                      </ButtonsOptions>
                      <ButtonsOptions
                        active={activeButton === "conferencia"}
                        onClick={() => handleButtonClick("conferencia")}
                        className="col-6"
                      >
                        Conferência do Material
                      </ButtonsOptions>
                    </Row>
                  </Col>
                  {activeButton === "descarte" && (
                    <Form>
                      <DivForm>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicCompany">
                              <Form.Label className="labelControl">
                                Gerência:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                value={selectedRequest?.administration}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicSector">
                              <Form.Label className="labelControl">
                                Setor gerador:
                              </Form.Label>
                              <Form.Control
                                type="text"
                                disabled
                                value={selectedRequest?.setor_generator}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicName">
                              <Form.Label>Complexo industrial:</Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.industrial}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicDate">
                              <Form.Label className="labelControl mr-2">
                                Data:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={
                                  selectedRequest &&
                                  moment(
                                    selectedRequest.date_solicitation
                                  ).format("DD/MM/YYYY")
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicObs">
                              <Form.Label className="mr-2">Resíduo:</Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.residue}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicObs">
                              <Form.Label className="mr-2">
                                Acondicionamento:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.stored_location}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicDate">
                              <Form.Label className="mr-2">
                                Tara do veículo:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.tare_vehicle}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicAmount">
                              <Form.Label className="labelControl mr-2">
                                Peso bruto do veículo:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="number"
                                value={selectedRequest?.gross_weight}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicDate">
                              <Form.Label className="mr-2">
                                Peso líquido:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.net_weight}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicAmount">
                              <Form.Label className="labelControl mr-2">
                                Representante:
                              </Form.Label>
                              <Form.Control
                                disabled
                                type="text"
                                value={selectedRequest?.representative}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </DivForm>
                    </Form>
                  )}
                  {activeButton === "conferencia" && (
                    <Form onSubmit={handleSubmit}>
                      <DivForm>
                        {successMessage && (
                          <Alert
                            variant="success"
                            onClose={() => setSuccessMessage(false)}
                            dismissible
                          >
                            <StyledFontAwesomeIcon icon={faCircleCheck} />
                            Conferência feita com sucesso!
                          </Alert>
                        )}
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>
                                Veículo
                                <Tooltips>
                                  <ButtonTooltip
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    disabled
                                    title="Tipo do veículo, ex: caminhão munck, caminhonete, caminhão tanque e etc."
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                value={vehicle}
                                placeholder="Informe o veículo"
                                onChange={(e) => setVehicle(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicLastName">
                              <Form.Label>
                                Placa
                                <Tooltips>
                                  <ButtonTooltip
                                    disabled
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Placa de identificação do veículo"
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Informe a placa do veículo"
                                value={licensePlate}
                                onChange={(e) =>
                                  setLicensePlate(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>
                                Motorista
                                <Tooltips>
                                  <ButtonTooltip
                                    disabled
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Nome do motorista responsável pelo transporte dos resíduos até as áreas da CMD. Descrevê-lo por extenso e de forma legível"
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Informe o nome do motorista"
                                value={motorist}
                                onChange={(e) => setMotorist(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicLastName">
                              <Form.Label>
                                Matricula do motorista
                                <Tooltips>
                                  <ButtonTooltip
                                    disabled
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title=" Matrícula do motorista responsável pelo transporte dos resíduos até as áreas da CMD"
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Informe a matricula do motorista"
                                value={registrationMotorist}
                                onChange={(e) =>
                                  setRegistrationMotorist(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>
                                Responsável pela CMD
                                <Tooltips>
                                  <ButtonTooltip
                                    disabled
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Nome do responsável pela área da CMD no momento do recebimento dos resíduos. Descrevê-lo por extenso e de forma legível."
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Informe o nome do responsavel"
                                value={responsibleAcess}
                                onChange={(e) =>
                                  setResponsibleAcess(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6} xs={12}>
                            <Form.Group controlId="formBasicLastName">
                              <Form.Label>
                                Matricula do responsavel
                                <Tooltips>
                                  <ButtonTooltip
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    disabled
                                    title=" Matrícula do Responsável pela área da CMD."
                                  >
                                    <StyledTooltipFontAwesome
                                      icon={faQuestionCircle}
                                    />
                                  </ButtonTooltip>
                                </Tooltips>
                              </Form.Label>
                              <Form.Control
                                required
                                type="text"
                                placeholder="Informe a matricula do responsavel"
                                value={registrationResponsible}
                                onChange={(e) =>
                                  setRegistrationResponsible(e.target.value)
                                }
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <MidTitle>CONFIRMAÇÃO DO PESO</MidTitle>
                        <Row>
                          <Col sm={4} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>Tara do veículo</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Informe o valor final da tara do veículo"
                                value={taraVehicle}
                                onChange={(e) => setTaraVehicle(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>Peso bruto</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Informe o valor final do peso bruto"
                                value={grossWeight}
                                onChange={(e) => setGrossWeight(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={4} xs={12}>
                            <Form.Group controlId="formBasicFirstName">
                              <Form.Label>Peso liquido</Form.Label>
                              <Form.Control
                                required
                                type="number"
                                placeholder="Informe o valor final do peso liquido"
                                value={netWeight}
                                onChange={(e) => setNetWeight(e.target.value)}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={6} xs={12} className="buttonForm">
                            <CustomButton
                              htmlFor="uploadImage"
                              className="buttonSolicitar"
                            >
                              Anexar imagem
                            </CustomButton>
                            <UploadImageInput
                              id="uploadImage"
                              onChange={handleImageUpload}
                            />
                          </Col>
                        </Row>
                        <Row>
                          {imageFile && (
                            <Row>
                              <PrevUpload sm={6} xs={12}>
                                <img
                                  src={imageFile}
                                  alt="Prévia"
                                  style={{
                                    maxWidth: "50px",
                                  }}
                                  ref={sigRef}
                                />
                              </PrevUpload>
                              <Col sm={3}>
                                <ButtonExitUpload onClick={handleRemoveImage}>
                                  <FontAwesomeIcon icon={faCircleXmark} />
                                </ButtonExitUpload>
                              </Col>
                            </Row>
                          )}
                        </Row>
                        <Row>
                          <Col xs={12}>
                            <Col sm={6} xs={12} className="buttonForm">
                              <Button
                                variant="primary"
                                type="submit"
                                className="buttonSolicitar"
                                disabled={disabled}
                              >
                                Confirmar
                              </Button>
                            </Col>
                          </Col>
                        </Row>
                      </DivForm>
                    </Form>
                  )}
                </Modal.Body>
              </Modal>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default ConferenceResidue;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledTable = styled(Table)`
  margin-top: 25px;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;

const ButtonsOptions = styled.button`
  background-color: transparent;
  border: none;
  padding: 1rem;
  width: 15rem;
  border-bottom: 2px solid
    ${(props) => (props.active ? "rgb(3 88 81)" : "#f3f3f3")};
`;

const Tooltips = styled.span`
  height: 10px;
  padding-left: 0.1rem;
`;

const ButtonTooltip = styled.button`
  background-color: transparent;
  border: none;
`;

const StyledTooltipFontAwesome = styled(FontAwesomeIcon)`
  color: rgb(48, 151, 143);
`;

const MidTitle = styled.span`
  font-family: "Helvetica Neue Medium Extended";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 3rem 0rem;
  color: rgb(48, 151, 143);
`;

const UploadImageInput = styled.input.attrs({
  type: "file",
})`
  /* Esconda o input do tipo file */
  display: none;
`;

const CustomButton = styled.label`
  display: inline-block;
  cursor: pointer;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;

  &:hover {
    background-color: #0056b3;
  }
`;

const PrevUpload = styled(Col)`
  border: 1px solid rgb(48, 151, 143);
  border-radius: 5px;
  height: 6rem;
  width: 7rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem;
`;

const ButtonExitUpload = styled.button`
    margin: 1rem -1.5rem;
    border: none;
    color red;
    background-color: transparent;
`;
