import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Col,
  Form,
  Row,
  Button,
  Alert,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleCheck,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";

function getDateTime(date = new Date()) {
  const timezoneOffset = date.getTimezoneOffset();
  const adjustedTime = date.getTime() - timezoneOffset * 60 * 1000;
  const adjustedDate = new Date(adjustedTime);
  const isoDateString = adjustedDate.toISOString();

  return isoDateString;
}

const MovementWaste = () => {
  const [max, setMax] = useState(0);
  const [administration, setAdministration] = useState("");
  const [industrial, setIndustrial] = useState("");
  const [sector, setSector] = useState("");
  const [datetime, setDatetime] = useState(new Date());
  const [residue, setResidue] = useState("");
  const [residueList, setResidueList] = useState([]);
  const [acondicionamento, setAcondicionamento] = useState("");
  //   const [amount, setAmount] = useState("");
  const [registration, setRegistration] = useState(max || 0);
  const [tareVehicle, setTareVehicle] = useState("");
  const [grossWeight, setGrossWeight] = useState("");
  const [netWeight, setNetWeight] = useState("");
  const [representativeList, setRepresentativeList] = useState([]);
  const [representative, setRepresentative] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await Requests.getLeavingsList();
        setResidueList(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const options = residueList.map((residue) => ({
    value: residue.id_leavings,
    label: `${residue.code_leavings} ${residue.name_leavings}`,
  }));

  useEffect(() => {
    const fetchDataUserApprovers = async () => {
      try {
        const response = await Requests.getUserListApprovers();
        setRepresentativeList(response);
      } catch (error) {
        console.log(error);
      }
    };

    fetchDataUserApprovers();
  }, []);

  const optionsUserApprovers = representativeList.map((user) => ({
    value: user.name,
    label: `${user.name} - ${user.sector}`,
  }));

  async function funcGetMaxRegistration() {
    const maxRegistration = await Requests.getLastRegistrationNumberMid();
    // console.log(maxRegistration._max.amount_residue);
    setMax(maxRegistration._max.amount_residue);
    setRegistration(maxRegistration._max.amount_residue + 1);
  }

  useEffect(() => {
    funcGetMaxRegistration();
  }, []);

  const handleSelectChange = (selectedOption) => {
    setResidue(selectedOption.value);
  };

  const handleSelectChangeUserApprovers = (selectedOption) => {
    setRepresentative(selectedOption.value);
  };

  const handleSubmit = (e) => {
    setDisabled(true);
    console.clear();
    e.preventDefault();
    const adjustedDatetime = getDateTime(datetime);
    const obj = {
      administration: administration,
      industrial: industrial,
      setor_generator: sector,
      date_solicitation: adjustedDatetime,
      residue: residue,
      stored_location: acondicionamento,
      // amount nao estava sendo usado no banco, para nao alterar a logica inteira, ele agora é usado para numero de registro, um pedido que teve depois pelo cliente
      amount_residue: registration,
      tare_vehicle: tareVehicle,
      gross_weight: grossWeight,
      net_weight: netWeight,
      representative: representative,
    };
    Requests.createMovementWaste(obj)
      .then((res) => {
        if (res) {
          console.log(res);
          if (res.invalido) {
            alert("Erro ao fazer a solicitação");
          } else {
            if (res) {
              setSuccessMessage(true);
              setTimeout(() => {
                window.location.href = "/home-environment";
              }, 750);
            }
          }
        }
      })
      .catch((res, err) => {
        console.log(res, err);
      })
      .finally(() => {
        setDisabled(false);
      });
  };

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SidebarInfraEnvironment />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>MID - Movimentação Interna de Resíduos</CardUser>
              <SpanSubtitle>Faça uma requisição</SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              {successMessage && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessage(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Solicitção feita com sucesso!
                </Alert>
              )}
              <Form onSubmit={handleSubmit}>
                <DivForm>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicUnit">
                        <Form.Label className="labelControl">
                          Gerência
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Gerência responsável pela geração do resíduos, ex: GAMAN, GAOFE, GAOFO, GAOSU e etc"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Informe a gerência"
                          required
                          value={administration}
                          onChange={(event) =>
                            setAdministration(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicSector">
                        <Form.Label className="labelControl">
                          Setor gerador/Empresa contratada
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Setor ou empresa contratada responsável pela geração do resíduo, ex: U-150, U-230, Empresa Fulano de Tal Ltda e etc"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Informe o setor gerador ou a empresa contratada"
                          required
                          value={sector}
                          onChange={(event) => setSector(event.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicTime">
                        <Form.Label className="labelControl">
                          Complexo Industrial
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Complexo Industrial onde o resíduo é gerado, ex: CIU1, CIU2, CCB, CAJ, etc"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Digite o numero da requisição"
                          value={industrial}
                          required
                          onChange={(event) =>
                            setIndustrial(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicDate">
                        <Form.Label className="labelControl">
                          Data
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Data de encaminhamento dos resíduos para o CMD"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={getDateTime(datetime).substring(0, 10)}
                          required
                          onChange={(event) => {
                            const dateValues = event.target.value
                              .split("-")
                              .map((v) => Number(v));
                            setDatetime((dt) => {
                              let newDate = new Date(dt);
                              newDate.setFullYear(
                                dateValues[0],
                                dateValues[1] - 1,
                                dateValues[2]
                              );
                              return newDate;
                            });
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </DivForm>
                <MidTitle>INFORMAÇÕES DO RESÍDUO DA ÁREA GERADORA</MidTitle>
                <DivForm>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicProducts">
                        <Form.Label className="labelControl mr-2">
                          Resíduo
                        </Form.Label>
                        <Select
                          options={options}
                          value={options.find(
                            (option) => option.value === residue
                          )}
                          onChange={handleSelectChange}
                          placeholder="Selecione um resíduo"
                          isSearchable={true}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: "45px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label>
                          Acondicionamento
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="A forma de acondicionamento dos resíduos, ex: tambor, big bag, caçamba e etc"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Informe o acondicionamento"
                          required
                          value={acondicionamento}
                          onChange={(event) =>
                            setAcondicionamento(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicObs">
                        <Form.Label className="mr-2">
                          Tara do veículo
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Peso do veículo vazio (sem resíduos)"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Informe a tara do veículo"
                          value={tareVehicle}
                          onChange={(event) =>
                            setTareVehicle(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicName">
                        <Form.Label>
                          Peso bruto do veículo
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Peso bruto do veículo =  peso do veículo + peso dos resíduos"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Informe o peso bruto do veículo"
                          required
                          value={grossWeight}
                          onChange={(event) =>
                            setGrossWeight(event.target.value)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicUnit">
                        <Form.Label className="labelControl">
                          Peso líquido
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Peso líquido =  Peso bruto do veículo - Peso do veículo vazio (sem resíduos)"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Informe o peso líquido"
                          required
                          value={netWeight}
                          onChange={(event) => setNetWeight(event.target.value)}
                        />
                      </Form.Group>
                    </Col>
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicUnit">
                        <Form.Label className="labelControl">
                          Representante do setor gerador ou preposto da empresa
                          contratada
                          <Tooltips>
                            <ButtonTooltip
                              disabled
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Nome do responsável do setor gerador ou preposto da empresa geradora, ex: José Maria da Silva"
                            >
                              <StyledTooltipFontAwesome
                                icon={faQuestionCircle}
                              />
                            </ButtonTooltip>
                          </Tooltips>
                        </Form.Label>
                        <Select
                          options={optionsUserApprovers}
                          value={optionsUserApprovers.find(
                            (option) => option.value === representative
                          )}
                          onChange={handleSelectChangeUserApprovers}
                          placeholder="Nome do responsável do setor gerador"
                          isSearchable={true}
                          styles={{
                            control: (provided) => ({
                              ...provided,
                              height: "45px",
                            }),
                          }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Form.Group
                        controlId="formBasicDate"
                      >
                        <Form.Label className="labelControl">
                          Requisição MID:
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={registration}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </DivForm>
                <Col xs={12}>
                  <Col sm={6} xs={12} className="buttonForm">
                    <Button
                      variant="primary"
                      type="submit"
                      className="buttonSolicitar"
                      disabled={disabled}
                    >
                      Solicitar
                    </Button>
                  </Col>
                </Col>
              </Form>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default MovementWaste;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const MidTitle = styled.span`
  font-family: "Helvetica Neue Medium Extended";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  display: flex;
  align-content: center;
  justify-content: center;
  margin: 2rem 0rem;
  color: rgb(48, 151, 143);
`;

const Tooltips = styled.span`
  height: 10px;
  padding-left: 0.1rem;
`;

const ButtonTooltip = styled.button`
  background-color: transparent;
  border: none;
`;

const StyledTooltipFontAwesome = styled(FontAwesomeIcon)`
  color: rgb(48, 151, 143);
`;
