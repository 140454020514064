import React from "react";
import styled from "styled-components";
import { userAllowed } from "../../../configs/userAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUserPlus,
  faClipboardCheck,
  faFileCirclePlus,
  faBarChart,
  faUtensils,
  faClipboard,
  faTable,
//   faFileSignature,
  faArrowRightFromBracket,
  faSignature
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
// import { isTablet } from "react-device-detect";

const icons = [
  {
    icon: faHome,
    url: "/home",
    tipos: ["Administrador", "Requisitante", "Aprovador"],
    label: "Home",
  },
  {
    icon: faUserPlus,
    url: "/user-register",
    tipos: ["Administrador"],
    label: "Cadastro",
  },
  {
    icon: faFileCirclePlus,
    url: "/request-service",
    tipos: ["Administrador", "Requisitante", "Aprovador"],
    label: "SPOT - Requisição",
  },
  {
    icon: faClipboardCheck,
    url: "/control-delivery",
    tipos: ["Administrador", "Aprovador"],
    label: "Controle de Entrega",
  },
//   isTablet && {
//     icon: faFileSignature,
//     url: "/request-signature",
//     tipos: ["Administrador", "Aprovador"],
//     label: "Folha de Assinatura",
//   },
  {
    icon: faUtensils,
    url: "/registration-products",
    tipos: ["Administrador"],
    label: "Registrar Produto",
  },
  {
    icon: faTable,
    url: "/tablita",
    tipos: ["Administrador"],
    label: "Tablita",
  },
  {
    icon: faClipboard,
    url: "/report-product",
    tipos: ["Administrador"],
    label: "Dashboard de Refeição",
  },
  {
    icon: faBarChart,
    url: "/report",
    tipos: ["Administrador"],
    label: "Relatório de Refeição",
  },
  {
    icon: faSignature,
    url: "/report-signature",
    tipos: ["Administrador"],
    label: "Assinaturas",
  },
  {
    icon: faArrowRightFromBracket,
    url: "/type-gateway",
    tipos: ["Administrador"],
    label: "Trocar Módulo",
  },
].filter(Boolean);

function BotaoSidebar({ url, icon, tipos, label }) {
  if (!userAllowed(tipos)) return null;

  return (
    <a href={url} className="buttonsMenu">
      <StyledFontAwesomeIcon icon={icon} />
      <span>{label}</span>
    </a>
  );
}

const SidebarInfra = () => {
  return (
    <>
      <Sidebar>
        <SidebarNavWrapper>
          <SpanName>Infra</SpanName>
          <SidebarNav>
            {icons.map((icon, index) => (
              <BotaoSidebar key={index} {...icon} />
            ))}
          </SidebarNav>
        </SidebarNavWrapper>
      </Sidebar>
    </>
  );
};

export default SidebarInfra;

const SpanName = styled.div`
  color: #00544d;
  font-family: Helvetica Neue Medium Extended;
  font-size: 25px;
  font-weight: 600;
  padding: 25px;
  text-transform: uppercase;
  padding: 15px 10px;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 100px;
  background-color: #ffffff;
  z-index: 2;
  position: fixed;
  z-index: 2;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    height: 60px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }

  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    display: flex;
    justify-content: center;
  }
`;

const SidebarNavWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 400px;
  }

  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const SidebarNav = styled.a`
  flex-grow: 1;
  width: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 2;
  text-decoration: none;
  @media screen and (max-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  padding: 15px;
  &:hover {
    color: #fff;
  }
  @media screen and (max-width: 768px) {
    padding: 10px;
  }
`;
