import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faFileCirclePlus,
  faBarChart,
  faUserPlus,
  faUtensils,
  faTable,
  faClipboard,
  // faFileSignature,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Header from "../../components/template/header/header";
import { userAllowed } from "../../configs/userAllowed";
import formatDate from "../../utils/functions/formatDate";
// import { isTablet } from "react-device-detect";

function BotaoHome({ clickBtn, icon, textBtn, textSpan, tipos }) {
  if (!userAllowed(tipos)) return;

  return (
    <Col sm={6} xs={12} className="cards-position">
      <Card className="cardsHome">
        <ButtonHome onClick={clickBtn}>
          <StyledFontAwesome icon={icon} />
          <TextButton>{textBtn}</TextButton>
          <SpanDescription>{textSpan}</SpanDescription>
        </ButtonHome>
      </Card>
    </Col>
  );
}

const Home = () => {
  const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));

  function handleClickControl() {
    window.location.href = "/control-delivery";
  }

  function handleClickRegister() {
    window.location.href = "/user-register";
  }

  function handleClickRequestService() {
    window.location.href = "/request-service";
  }

  // function handleClickRequestSignature() {
  //   window.location.href = "/request-signature";
  // }

  function handleClickTablita() {
    window.location.href = "/tablita";
  }

  function handleClickReport() {
    window.location.href = "/report";
  }

  function handleClickRegistrarionProducts() {
    window.location.href = "/registration-products";
  }

  function handleClickReportProduct() {
    window.location.href = "/report-product";
  }

  function handleClickReportSignature() {
    window.location.href = "/report-signature";
  }

  const homeBtns = [
    {
      clickBtn: handleClickRegister,
      icon: faUserPlus,
      textBtn: "Cadastro",
      textSpan: "Cadastre novos usuários",
      tipos: ["Administrador"],
    },
    {
      clickBtn: handleClickRequestService,
      icon: faFileCirclePlus,
      textBtn: "SPOT - Requisição",
      textSpan: "Faça uma requisição de serviços",
      tipos: ["Administrador", "Requisitante", "Aprovador"],
    },
    {
      clickBtn: handleClickControl,
      icon: faClipboardCheck,
      textBtn: "Controle de Entrega",
      textSpan: "Faça o controle de entrega dos seus produtos",
      tipos: ["Administrador", "Aprovador"],
    },
    // isTablet && {
    //   clickBtn: handleClickRequestSignature,
    //   icon: faFileSignature,
    //   textBtn: "Folha de Assinatura",
    //   textSpan: "Faça a autorização para entrada ao restaurante ",
    //   tipos: ["Administrador", "Aprovador"],
    // },
    {
      clickBtn: handleClickRegistrarionProducts,
      icon: faUtensils,
      textBtn: "Cadastrar/Alterar produtos",
      textSpan: "Cadastre ou altere seus produtos",
      tipos: ["Administrador"],
    },
    {
      clickBtn: handleClickTablita,
      icon: faTable,
      textBtn: "Tablita",
      textSpan: "Veja e edite seus valores da tablita",
      tipos: ["Administrador"],
    },
    {
      clickBtn: handleClickReportProduct,
      icon: faClipboard,
      textBtn: "Dashboard de Refeição",
      textSpan: "Veja o seu dashboard de refeição",
      tipos: ["Administrador"],
    },
    {
      clickBtn: handleClickReport,
      icon: faBarChart,
      textBtn: "Relatório de Refeições",
      textSpan: "Veja o seu relatório de refeições",
      tipos: ["Administrador"],
    },
    {
      clickBtn: handleClickReportSignature,
      icon: faSignature,
      textBtn: "Listagem de assinaturas",
      textSpan: "Veja as assinaturas",
      tipos: ["Administrador"],
    },
  ];

  return (
    <>
      <Header />
      <div style={{ display: "flex" }}>
        <SidebarInfra />
        <MainContainer>
          <Col
            xs={12}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Card className="cardDados">
              {usuarioLogado && (
                <p className="name">
                  {formatDate(new Date()).saudacao +
                    ", " +
                    usuarioLogado.name.split(" ")[0] +
                    "!"}
                </p>
              )}
              <p className="date">{formatDate(new Date()).data}</p>
            </Card>
          </Col>
          <Col xs={12}>
            <Row className="rowCards">
              <CardsHome>
                <Col xs={12} className="styledButtonsHome">
                  <Row>
                    {homeBtns.map((btn) => (
                      <BotaoHome {...btn} />
                    ))}
                  </Row>
                </Col>
              </CardsHome>
            </Row>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default Home;

const MainContainer = styled(Container)`
  display: flex;
  padding: 0rem 3rem;
  align-items: flex-start;
  flex-direction: column;
  @media screen and (max-width: 768px) {
    height: 100%;
    margin-top: 40px;
  }
`;

const CardsHome = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin: 10px 0px 10px 50px;
  @media screen and (max-width: 768px) {
    margin: 0;
  }
`;

const ButtonHome = styled.button`
  width: 20rem;
  border: none;
  height: 100vh;
  color: rgb(48, 151, 143);
  background-color: transparent;
  @media screen and (max-width: 768px) {
    width: 10rem;
  }
`;

const TextButton = styled.span`
  font-family: "Helvetica Neue Medium Extended";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-content: center;
  justify-content: center;
`;

const SpanDescription = styled.span`
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 13px;
  font-family: "Helvetica Neue Medium Extended";
  font-style: normal;
  color: gray;
  padding: 10px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
  height: 35px;
  padding: 20px;
`;
