import React, { useRef, useState, useEffect } from "react";
import {
    Container,
    Card,
    Col,
    Form,
    Row,
    Button,
    Alert,
} from "react-bootstrap";
import styled from "styled-components";
import Header from "../../components/template/header/header";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import SignatureCanvas from "react-signature-canvas";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";

const RequestSignature = () => {
    const usuarioLogado = JSON.parse(sessionStorage.getItem("usuarioLogado"));
    const [unit, setUnit] = useState(usuarioLogado.unit);
    const [restaurant, setRestaurant] = useState("");
    const [name, setName] = useState("");
    const [type, setType] = useState("");
    const [reason, setReason] = useState("");
    const [registration, setRegistration] = useState("");
    const [successMessage, setSuccessMessage] = useState(false);
    const [company, setCompany] = useState("");
    const [sector, setSector] = useState("");
    const [visitor, setVisitor] = useState("");
    const [sectorVisitor, setSectorVisitor] = useState("");
    const [signature, setSignature] = useState("");
    const sigRef = useRef();

    const handleSignatureEnd = () => {
        const signatureImage = sigRef.current.toDataURL();
        const byteString = atob(signatureImage.split(",")[1]);
        const mimeString = signatureImage
            .split(",")[0] 
            .split(":")[1]
            .split(";")[0];
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const intArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            intArray[i] = byteString.charCodeAt(i);
        }

        const signatureFile = new Blob([arrayBuffer], { type: mimeString });
        setSignature(signatureFile);
    };

    const clearSignature = () => {
        sigRef.current.clear();
        setSignature(null);
    };

    useEffect(() => {
        console.log(signature);
    }, [signature]);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("name_requester", name);
        formData.append("reason", reason);
        formData.append("type_meal", type);
        formData.append("restaurant", restaurant);
        formData.append("requester_registration", registration);
        formData.append("sector", sector);
        formData.append("company_visitant", visitor);
        formData.append("unit", usuarioLogado.unit);
        formData.append("sector_visitant", sectorVisitor);
        formData.append("responsible_signature", signature);
        Requests.createSignature(formData)
            .then((res) => {
                if (res) {
                    console.log(res);
                    if (res.invalido) {
                        alert("Erro ao assinar a requisição");
                    } else {
                        setSuccessMessage(true);
                        setTimeout(() => {
                            window.location.href = "/home";
                        }, 750);
                    }
                }
            })
            .catch((res, err) => {
                console.log(res, err);
            });
    };

    const handleSelectChangeUnit = (event) => {
        setUnit(event.target.value);
    };

    const handleSelectChangeType = (event) => {
        setType(event.target.value);
    };

    const handleSelectChangeReason = (event) => {
        setReason(event.target.value);
    };

    if (!userAllowed(["Administrador", "Aprovador"])) {
        window.location.href = "/home";
        alert("Não permitido para o seu usuário");
        return <></>;
    }

    return (
        <>
            <Header />
            <div style={{ display: "flex" }}>
                <SidebarInfra />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>Folha de assinatura</CardUser>
                            <SpanSubtitle>
                                Faça uma requisição de refeição da empresa
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <Col xs={12} className="controlForm">
                        <Container>
                            {successMessage && (
                                <Alert
                                    variant="success"
                                    onClose={() => setSuccessMessage(false)}
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon
                                        icon={faCircleCheck}
                                    />
                                    Liberado com sucesso!
                                </Alert>
                            )}
                            <Form>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicUnit">
                                            <Form.Label className="labelControl">
                                                Unidade:
                                            </Form.Label>
                                            <Form.Select
                                                value={unit}
                                                disabled
                                                onChange={
                                                    handleSelectChangeUnit
                                                }
                                                aria-label="Selecione um tipo"
                                                style={{ height: "45px" }}
                                                required
                                            >
                                                <option value="" disabled>
                                                    Selecione a unidade
                                                </option>
                                                <option value="Araxá">
                                                    Araxá
                                                </option>
                                                <option value="Patrocinio">
                                                    Patrocínio
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicRestaurant">
                                            <Form.Label className="labelControl">
                                                Restaurante
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o nome do restaurante"
                                                value={restaurant}
                                                required
                                                onChange={(event) =>
                                                    setRestaurant(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicName">
                                            <Form.Label className="labelControl">
                                                Nome do solicitante
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Digite o nome do solicitante"
                                                required
                                                value={name}
                                                onChange={(event) =>
                                                    setName(event.target.value)
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicRegistration">
                                            <Form.Label className="labelControl">
                                                Matrícula
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Informe a matrícula do solicitante"
                                                value={registration}
                                                required
                                                onChange={(event) =>
                                                    setRegistration(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicLunch">
                                            <Form.Label className="labelControl">
                                                Tipo de refeição
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Selecione uma refeição"
                                                style={{ height: "45px" }}
                                                required
                                                onChange={
                                                    handleSelectChangeType
                                                }
                                            >
                                                <option value="">
                                                    Selecione uma refeição
                                                </option>
                                                <option value="Desjejum">
                                                    Desjejum
                                                </option>
                                                <option value="Almoço">
                                                    Almoço
                                                </option>
                                                <option value="Jantar">
                                                    Jantar
                                                </option>
                                                <option value="Ceia">
                                                    Ceia
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicAmount">
                                            <Form.Label className="labelControl">
                                                Motivo
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Selecione um motivo"
                                                style={{ height: "45px" }}
                                                onChange={
                                                    handleSelectChangeReason
                                                }
                                                required
                                            >
                                                <option value="">
                                                    Selecione um motivo
                                                </option>
                                                <option value="Esqueceu o crachá">
                                                    Esqueceu o crachá
                                                </option>
                                                <option value="Erro na leitura">
                                                    Erro na leitura
                                                </option>
                                                <option value="Invalido">
                                                    Usuário Inválido
                                                </option>
                                                <option value="Visitante">
                                                    Visitante
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicLunch">
                                            <Form.Label className="labelControl">
                                                Empresa
                                            </Form.Label>
                                            <Form.Select
                                                aria-label="Selecione a empresa"
                                                style={{
                                                    height: "45px",
                                                }}
                                                required
                                                onChange={(event) => {
                                                    setCompany(
                                                        event.target.value
                                                    );
                                                    setSector("");
                                                    setVisitor("");
                                                }}
                                            >
                                                <option value="">
                                                    Selecione a empresa
                                                </option>
                                                <option value="mosaic">
                                                    Mosaic
                                                </option>
                                                <option value="visitante">
                                                    Visitante ou outros
                                                </option>
                                            </Form.Select>
                                        </Form.Group>
                                    </Col>
                                    {company === "mosaic" && (
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicSector">
                                                <Form.Label className="labelControl">
                                                    Setor
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Informe o setor do solicitante"
                                                    value={sector}
                                                    onChange={(event) =>
                                                        setSector(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}

                                    {company === "visitante" && (
                                        <Col sm={6} xs={12}>
                                            <Form.Group controlId="formBasicVisitor">
                                                <Form.Label className="labelControl">
                                                    Empresa visitante
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Informe o nome da empresa visitante"
                                                    value={visitor}
                                                    onChange={(event) =>
                                                        setVisitor(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                    )}
                                </Row>
                                {company === "visitante" && (
                                    <Col sm={6} xs={12}>
                                        <Form.Group controlId="formBasicVisitor">
                                            <Form.Label className="labelControl">
                                                Setor que está visitando
                                            </Form.Label>
                                            <Form.Control
                                                type="text"
                                                placeholder="Informe o nome do setor da empresa que está visitando"
                                                value={sectorVisitor}
                                                onChange={(event) =>
                                                    setSectorVisitor(
                                                        event.target.value
                                                    )
                                                }
                                            />
                                        </Form.Group>
                                    </Col>
                                )}
                                <Col
                                    xs={12}
                                    sm={12}
                                    className="d-flex justify-content-center"
                                >
                                    <SignatureCanvas
                                        penColor="black"
                                        canvasProps={{ className: "signature" }}
                                        ref={sigRef}
                                        onEnd={handleSignatureEnd}
                                    />
                                </Col>
                            </Form>
                            <Col xs={12}>
                                <Row>
                                    <Col sm={6} xs={12} className="buttonForm">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="buttonSolicitar"
                                            onClick={clearSignature}
                                        >
                                            Limpar assinatura
                                        </Button>
                                    </Col>
                                    <Col sm={6} xs={12} className="buttonForm">
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="buttonSolicitar"
                                            onClick={handleSubmit}
                                        >
                                            Confirmar
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Container>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default RequestSignature;

const MainContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
`;

const CardUser = styled.div`
    font-family: Helvetica Neue Medium Extended;
    font-size: 28px;
    font-weight: 5600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Medium Extended;
    font-size: 18px;
    color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #00544d;
    margin-right: 10px;
`;
