import React, { useState } from "react";
import {
  Container,
  Col,
  Card,
  Form,
  Row,
  Button,
  Table,
  Spinner,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import Requests from "../../configs/requets";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import { userAllowed } from "../../configs/userAllowed";
// import { parseISO, startOfDay, endOfDay } from "date-fns";

const Report = () => {
  const [date, setDate] = useState(new Date().toISOString().substring(0, 10));
  // const [date, setDate] = useState(new Date());
  const [dateEnd, setDateEnd] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const [selectedOption, setSelectedOption] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportTerceiros, setReportRerceiros] = useState([]);
  const [reportVisitants, setReportVisitants] = useState([]);
  const [reportSpot, setReportSpot] = useState([]);
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useState("");
  const [totalSoma, setTotalSoma] = useState();
  const [totalSomaTerceiros, setTotalSomaTerceiros] = useState();
  const [totalSomaVisitantes, setTotalSomaVisitantes] = useState();
  const [totalSomaSpot, setTotalSomaSpot] = useState();

  const handleRequestReportMealMosaic = async () => {
    try {
      setLoading(true);
      const startDate = (formatarData(date))
      const endDate = (formatarData(dateEnd))
      const responseDetails = await Requests.getReportMealMosaic(
        startDate,
        endDate,
        unit
      );

      console.log("data inicio", startDate);
      console.log("data fim", endDate)
      setTotalSoma(responseDetails.reports.somaTotal);
      setReportData(responseDetails.reports.resultadoDetalhes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReportOutsourcingMosaic = async () => {
    try {
      setLoading(true);
      const startDate = (formatarData(date))
      const endDate = (formatarData(dateEnd))
      const response = await Requests.getReportOutsourcingMosaic(
        startDate,
        endDate,
        unit
      );

      setTotalSomaTerceiros(response.reports.somaTotal);
      setReportRerceiros(response.reports.resultadoDetalhes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReportVisitantMosaic = async () => {
    try {
      setLoading(true);
      const startDate = (formatarData(date))
      const endDate = (formatarData(dateEnd))
      const response = await Requests.getReportVisitantMosaic(
        startDate,
        endDate,
        unit
      );

      setTotalSomaVisitantes(response.reports.somaTotal);
      setReportVisitants(response.reports.resultadoDetalhes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReportSpotMosaic = async () => {
    try {
      setLoading(true);
      const startDate = (formatarData(date))
      const endDate = (formatarData(dateEnd))
      const response = await Requests.getReportSpotMosaic(
        startDate,
        endDate,
        unit
      );

      setTotalSomaSpot(response.reports.somaTotal);
      setReportSpot(response.reports.resultadoDetalhes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleRequestReportGeral = async () => {
    try {
      setLoading(true);
      const startDate = (formatarData(date))
      const endDate = (formatarData(dateEnd))
      const responseDetails = await Requests.getReportMealMosaic(
        startDate,
        endDate,
        unit
      );

      const responseTerceiros = await Requests.getReportOutsourcingMosaic(
        startDate,
        endDate,
        unit
      );
      const responseVisitantes = await Requests.getReportVisitantMosaic(
        startDate,
        endDate,
        unit
      );
      const responseSpot = await Requests.getReportSpotMosaic(
        startDate,
        endDate,
        unit
      );

      // Define os relatórios filtrados nos estados
      setTotalSoma(responseDetails.reports.somaTotal);
      setReportData(responseDetails.reports.resultadoDetalhes);
      setTotalSomaTerceiros(responseTerceiros.reports.somaTotal);
      setReportRerceiros(responseTerceiros.reports.resultadoDetalhes);
      setTotalSomaVisitantes(responseVisitantes.reports.somaTotal);
      setReportVisitants(responseVisitantes.reports.resultadoDetalhes);
      setTotalSomaSpot(responseSpot.reports.somaTotal);
      setReportSpot(responseSpot.reports.resultadoDetalhes);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleSelectChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  function formatCurrency(value) {
    return parseFloat(value).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  }

  const totalRefeicaoMosaic =
    reportData.length > 0 && reportData[0] && reportData[0].refeicao_valor
      ? totalSoma * reportData[0].refeicao_valor
      : 0;
  const totalRefeicaoTerceiros =
    reportTerceiros.length > 0 &&
      reportTerceiros[0] &&
      reportTerceiros[0].refeicao_valor
      ? totalSomaTerceiros * reportTerceiros[0].refeicao_valor
      : 0;
  const totalRefeicaoVisitante =
    reportVisitants.length > 0 &&
      reportVisitants[0] &&
      reportVisitants[0].refeicao_valor
      ? totalSomaVisitantes * reportVisitants[0].refeicao_valor
      : 0;
  const totalRefeicaoSpot =
    reportSpot && reportSpot.length > 0
      ? reportSpot.reduce((total, product) => {
        const productValue = parseFloat(product.valor_unitario);
        const productQuantity = parseFloat(product.refeicao_quantidade);

        if (!isNaN(productValue) && !isNaN(productQuantity)) {
          return total + productValue * productQuantity;
        } else {
          console.warn(`Valores inválidos encontrados em product:`, product);
          return total;
        }
      }, 0)
      : 0;

  const totalGeral =
    totalRefeicaoMosaic +
    totalRefeicaoTerceiros +
    totalRefeicaoVisitante +
    totalRefeicaoSpot;

  function formatarData(dateString) {
    const dateOri = new Date(dateString);
    const date = new Date(
      dateOri.setHours(dateOri.getHours() + dateOri.getTimezoneOffset() / 60)
    );

    // console.log("date", date)
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().padStart(4, "0");

    return `${year}-${month}-${day}`;
  }

  function formatarDataParaExibicao(dateString) {
    const dateOri = new Date(dateString);
    // Ajusta a data para o fuso horário local
    const date = new Date(dateOri.getTime() + dateOri.getTimezoneOffset() * 60000);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString().padStart(4, "0");

    return `${day}-${month}-${year}`;
  }

  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex", minHeight: "100vh" }}>
        <SidebarInfra />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Relatório de Refeições</CardUser>
              <SpanSubtitle>
                Veja o relatório de refeições do restaurante
              </SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              <Form>
                <Row>
                  <Col sm={6} xs={12} className="mb-4">
                    <Form.Label className="labelControl">
                      Selecione uma opção de relatório:
                    </Form.Label>
                    <Form.Select
                      onChange={(e) => setSelectedOption(e.target.value)}
                      value={selectedOption}
                    >
                      <option value={null}>Selecionar uma opção</option>
                      <option value="mosaic">Refeição Mosaic</option>
                      <option value="terceiros">Refeição Terceiros</option>
                      <option value="visitantes">Refeição Visitantes</option>
                      <option value="spot">Refeição Spot</option>
                      <option value="geral">Relatório Geral</option>
                    </Form.Select>
                  </Col>
                </Row>
                {selectedOption === "mosaic" && (
                  <div>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Período:
                            </Form.Label>
                            <Col sm={12} xs={12}>
                              <Row>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={(event) =>
                                      setDate(event.target.value)
                                    }
                                  />
                                </Col>
                                <Col sm={1}>
                                  <SpanText>Até:</SpanText>
                                </Col>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={dateEnd}
                                    onChange={(event) =>
                                      setDateEnd(event.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicUnit">
                            <Form.Label className="labelControl">
                              Unidade
                            </Form.Label>
                            <Form.Select
                              value={unit}
                              required
                              onChange={(event) =>
                                handleSelectChangeUnit(event)
                              }
                              style={{
                                height: "45px",
                              }}
                            >
                              <option value="" disabled>
                                Selecione a unidade
                              </option>
                              <option value="CMA">Araxá</option>
                              <option value="CMP">Patrocínio</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    <Col xs={12}>
                      <Col sm={6} xs={12} className="buttonForm mb-5">
                        <Button
                          variant="primary"
                          type="button"
                          className="buttonCadaster"
                          onClick={handleRequestReportMealMosaic}
                          disabled={loading}
                        >
                          {loading
                            ? "Carregando..."
                            : "Relatório Refeição Mosaic"}
                        </Button>
                      </Col>
                    </Col>
                    {loading && (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5rem",
                        }}
                      >
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Carregando...</span>
                          </Spinner>
                        </div>
                      </Container>
                    )}
                    {reportData && reportData.length > 0 && (
                      <Container
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Col
                          xs={12}
                          sm={12}
                          className="table-movement table-conference-cmd mt-5"
                        >
                          <h4 className="mt-5 mb-3">Registros:</h4>
                          <Table striped bordered hover id="report-table">
                            <thead>
                              <tr>
                                <th>Centro de Custo</th>
                                <th>Data da Refeição</th>
                                <th>Nome</th>
                                <th>Tipo de Refeição</th>
                                <th>Quantidade</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportData.map((report) => (
                                <tr key={report}>
                                  <td>{report.centro_custo}</td>
                                  <td>{formatarDataParaExibicao(report.data_refeicao)}</td>
                                  <td>{report.nome}</td>
                                  <td>{report.tipo_refeicao}</td>
                                  <td>{report.refeicao_quantidade}</td>
                                  <td>{report.refeicao_valor}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Container>
                    )}
                  </div>
                )}
                {selectedOption === "terceiros" && (
                  <div>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Período:
                            </Form.Label>
                            <Col sm={12} xs={12}>
                              <Row>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={(event) =>
                                      setDate(event.target.value)
                                    }
                                  />
                                </Col>
                                <Col sm={1}>
                                  <SpanText>Até:</SpanText>
                                </Col>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={dateEnd}
                                    onChange={(event) =>
                                      setDateEnd(event.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicUnit">
                            <Form.Label className="labelControl">
                              Unidade
                            </Form.Label>
                            <Form.Select
                              value={unit}
                              required
                              onChange={(event) =>
                                handleSelectChangeUnit(event)
                              }
                              style={{
                                height: "45px",
                              }}
                            >
                              <option value="" disabled>
                                Selecione a unidade
                              </option>
                              <option value="CMA">Araxá</option>
                              <option value="CMP">Patrocínio</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    <Col xs={12}>
                      <Col sm={6} xs={12} className="buttonForm mb-5">
                        <Button
                          variant="primary"
                          type="button"
                          className="buttonCadaster"
                          onClick={handleRequestReportOutsourcingMosaic}
                          disabled={loading}
                        >
                          {loading
                            ? "Carregando..."
                            : "Relatório Refeição Terceiros"}
                        </Button>
                      </Col>
                    </Col>
                    {loading && (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5rem",
                        }}
                      >
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Carregando...</span>
                          </Spinner>
                        </div>
                      </Container>
                    )}
                    {reportTerceiros && reportTerceiros.length > 0 && (
                      <Container
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Col
                          xs={12}
                          sm={12}
                          className="table-movement table-conference-cmd mt-5"
                        >
                          <h4 className="mt-5 mb-3">Registros:</h4>
                          <Table striped bordered hover id="report-table">
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Centro de custo</th>
                                <th>Nome</th>
                                <th>Refeição</th>
                                <th>Refeição Quantidade</th>
                                <th>Refeição Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportTerceiros.map((report) => (
                                <tr key={report}>
                                  <td>
                                    {formatarDataParaExibicao(
                                      report.data_refeicao_terceiro
                                    )}
                                  </td>
                                  <td>{report.centro_custo}</td>
                                  <td>{report.nome}</td>
                                  <td>{report.refeicao}</td>
                                  <td>{report.refeicao_quantidade}</td>
                                  <td>{report.refeicao_valor}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Container>
                    )}
                  </div>
                )}
                {selectedOption === "visitantes" && (
                  <div>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Período:
                            </Form.Label>
                            <Col sm={12} xs={12}>
                              <Row>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={(event) =>
                                      setDate(event.target.value)
                                    }
                                  />
                                </Col>
                                <Col sm={1}>
                                  <SpanText>Até:</SpanText>
                                </Col>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={dateEnd}
                                    onChange={(event) =>
                                      setDateEnd(event.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicUnit">
                            <Form.Label className="labelControl">
                              Unidade
                            </Form.Label>
                            <Form.Select
                              value={unit}
                              required
                              onChange={(event) =>
                                handleSelectChangeUnit(event)
                              }
                              style={{
                                height: "45px",
                              }}
                            >
                              <option value="" disabled>
                                Selecione a unidade
                              </option>
                              <option value="CMA">Araxá</option>
                              <option value="CMP">Patrocínio</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    <Col xs={12}>
                      <Col sm={6} xs={12} className="buttonForm mb-5">
                        <Button
                          variant="primary"
                          type="button"
                          className="buttonCadaster"
                          onClick={handleRequestReportVisitantMosaic}
                          disabled={loading}
                        >
                          {loading
                            ? "Carregando..."
                            : "Relatório Refeição Visitantes"}
                        </Button>
                      </Col>
                    </Col>
                    {loading && (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5rem",
                        }}
                      >
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Carregando...</span>
                          </Spinner>
                        </div>
                      </Container>
                    )}
                    {reportVisitants && reportVisitants.length > 0 && (
                      <Container
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Col
                          xs={12}
                          sm={12}
                          className="table-movement table-conference-cmd mt-5"
                        >
                          <h4 className="mt-5 mb-3">Registros:</h4>
                          <Table striped bordered hover id="report-table">
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Centro de custo</th>
                                <th>Visitante</th>
                                <th>Visitado</th>
                                <th>Refeição</th>
                                <th>Refeição Quantidade</th>
                                <th>Valor</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportVisitants.map((report) => (
                                <tr key={report}>
                                  <td>
                                    {formatarDataParaExibicao(
                                      report.data_refeicao_visitante
                                    )}
                                  </td>
                                  <td>{report.centro_custo}</td>
                                  <td>{report.visitante}</td>
                                  <td>{report.visitado}</td>
                                  <td>{report.refeicao}</td>
                                  <td>{report.refeicao_quantidade}</td>
                                  <td>{report.refeicao_valor}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Container>
                    )}
                  </div>
                )}
                {selectedOption === "spot" && (
                  <div>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Período:
                            </Form.Label>
                            <Col sm={12} xs={12}>
                              <Row>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={(event) =>
                                      setDate(event.target.value)
                                    }
                                  />
                                </Col>
                                <Col sm={1}>
                                  <SpanText>Até:</SpanText>
                                </Col>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={dateEnd}
                                    onChange={(event) =>
                                      setDateEnd(event.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicUnit">
                            <Form.Label className="labelControl">
                              Unidade
                            </Form.Label>
                            <Form.Select
                              value={unit}
                              required
                              onChange={(event) =>
                                handleSelectChangeUnit(event)
                              }
                              style={{
                                height: "45px",
                              }}
                            >
                              <option value="" disabled>
                                Selecione a unidade
                              </option>
                              <option value="CMA">Araxá</option>
                              <option value="CMP">Patrocínio</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    <Col xs={12}>
                      <Col sm={6} xs={12} className="buttonForm mb-5">
                        <Button
                          variant="primary"
                          type="button"
                          className="buttonCadaster"
                          onClick={handleRequestReportSpotMosaic}
                          disabled={loading}
                        >
                          {loading
                            ? "Carregando..."
                            : "Relatório Refeição Spot"}
                        </Button>
                      </Col>
                    </Col>
                    {loading && (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5rem",
                        }}
                      >
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Carregando...</span>
                          </Spinner>
                        </div>
                      </Container>
                    )}
                    {reportSpot && reportSpot.length > 0 && (
                      <Container
                        style={{
                          marginTop: "5rem",
                        }}
                      >
                        <Col
                          xs={12}
                          sm={12}
                          className="table-movement table-conference-cmd mt-5"
                        >
                          <h4 className="mt-5 mb-3">Registros:</h4>
                          <Table striped bordered hover id="report-table">
                            <thead>
                              <tr>
                                <th>Data</th>
                                <th>Requisição</th>
                                <th>Nome</th>
                                <th>Refeição</th>
                                <th>Quantidade</th>
                                <th>Valor Un.</th>
                                <th>Valor Total</th>
                                <th>Centro de Custo</th>
                                <th>Cod. SAP</th>
                                <th>Rateio</th>
                              </tr>
                            </thead>
                            <tbody>
                              {reportSpot.map((report) => (
                                <tr key={report}>
                                  <td>{formatarDataParaExibicao(report.data_refeicao)}</td>
                                  <td>{report.requisicao}</td>
                                  <td>{report.nome}</td>
                                  <td>{report.tipo_refeicao}</td>
                                  <td>{report.refeicao_quantidade}</td>
                                  <td>{report.valor_unitario}</td>
                                  <td>
                                    {(
                                      report.refeicao_quantidade *
                                      report.valor_unitario
                                    ).toFixed(2)}
                                  </td>
                                  <td>{report.centro_custo}</td>
                                  <td>{report.codigo_sap}</td>
                                  <td>{report.rateio_centro_custo}</td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </Col>
                      </Container>
                    )}
                  </div>
                )}
                {selectedOption === "geral" && (
                  <div>
                    <DivForm>
                      <Row>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicCompany">
                            <Form.Label className="labelControl">
                              Período:
                            </Form.Label>
                            <Col sm={12} xs={12}>
                              <Row>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={date}
                                    onChange={(event) =>
                                      setDate(event.target.value)
                                    }
                                  />
                                </Col>
                                <Col sm={1}>
                                  <SpanText>Até:</SpanText>
                                </Col>
                                <Col sm={5} xs={12}>
                                  <Form.Control
                                    type="date"
                                    value={dateEnd}
                                    onChange={(event) =>
                                      setDateEnd(event.target.value)
                                    }
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Form.Group>
                        </Col>
                        <Col sm={6} xs={12}>
                          <Form.Group controlId="formBasicUnit">
                            <Form.Label className="labelControl">
                              Unidade
                            </Form.Label>
                            <Form.Select
                              value={unit}
                              required
                              onChange={(event) =>
                                handleSelectChangeUnit(event)
                              }
                              style={{
                                height: "45px",
                              }}
                            >
                              <option value="" disabled>
                                Selecione a unidade
                              </option>
                              <option value="CMA">Araxá</option>
                              <option value="CMP">Patrocínio</option>
                            </Form.Select>
                          </Form.Group>
                        </Col>
                      </Row>
                    </DivForm>
                    <Col xs={12}>
                      <Col sm={6} xs={12} className="buttonForm mb-5">
                        <Button
                          variant="primary"
                          type="button"
                          className="buttonCadaster"
                          onClick={handleRequestReportGeral}
                          disabled={loading}
                        >
                          {loading ? "Carregando..." : "Relatório Geral"}
                        </Button>
                      </Col>
                    </Col>
                    {loading && (
                      <Container
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "5rem",
                        }}
                      >
                        <div className="text-center mt-3">
                          <Spinner animation="border" role="status">
                            <span className="sr-only">Carregando...</span>
                          </Spinner>
                        </div>
                      </Container>
                    )}
                    <Container
                      style={{
                        marginTop: "5rem",
                      }}
                    >
                      <Col
                        xs={12}
                        sm={12}
                        className="table-movement table-conference-cmd mt-5"
                      >
                        <h4 className="mt-5 mb-3">Registros:</h4>
                        <Table striped bordered hover id="report-table">
                          <thead>
                            <tr>
                              <th>Refeição</th>
                              <th>Quantidade</th>
                              <th>Valor Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Refeição Mosaic</td>
                              <td>{totalSoma}</td>
                              <td>
                                {reportData.length > 0 &&
                                  reportData[0] &&
                                  reportData[0].refeicao_valor
                                  ? formatCurrency(
                                    totalSoma * reportData[0].refeicao_valor
                                  )
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Refeição Terceiros</td>
                              <td>{totalSomaTerceiros}</td>
                              <td>
                                {reportTerceiros.length > 0 &&
                                  reportTerceiros[0] &&
                                  reportTerceiros[0].refeicao_valor
                                  ? formatCurrency(
                                    totalSomaTerceiros *
                                    reportTerceiros[0].refeicao_valor
                                  )
                                  : 0}
                              </td>
                            </tr>
                            <tr>
                              <td>Refeição Visitante</td>
                              <td>{totalSomaVisitantes}</td>
                              <td>
                                {reportVisitants.length > 0 &&
                                  reportVisitants[0] &&
                                  reportVisitants[0].refeicao_valor
                                  ? formatCurrency(
                                    totalSomaVisitantes *
                                    reportVisitants[0].refeicao_valor
                                  )
                                  : 0}
                              </td>
                            </tr>
                            {/* <tr>
                              <td>SPOT</td>
                              <td>{totalSomaSpot}</td>
                              <td>
                                {reportSpot &&
                                reportSpot.length > 0 &&
                                reportSpot[0] &&
                                reportSpot[0].refeicao_valor
                                  ? formatCurrency(
                                      totalSomaSpot *
                                        reportSpot[0].refeicao_valor
                                    )
                                  : "N/A"}
                              </td>
                            </tr> */}
                            <tr>
                              <td>SPOT</td>
                              <td>{totalSomaSpot}</td>
                              <td>{formatCurrency(totalRefeicaoSpot)}</td>
                            </tr>
                            <tr>
                              <td
                                colSpan="2"
                                className="text-center font-weight-bold"
                              >
                                TOTAL
                              </td>
                              <td>{formatCurrency(totalGeral)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Container>
                  </div>
                )}
              </Form>
            </Container>
          </Col>
        </MainContainer>
      </div>
    </>
  );
};

export default Report;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const DivForm = styled.div`
  border: 1px solid #dcdcdc;
  padding: 20px;
  border-radius: 10px;
  background-color: #ffffff9e;
`;

const SpanText = styled.span`
  display: flex;
  justify-content: flex-end;
  padding-top: 7px;
  text-transform: uppercase;
  padding-left: 30px;

  @media screen and (max-width: 768px) {
    padding: 0;
    display: block;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px),
    (min-width: 1024px) and (max-width: 1199px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    padding-top: 0.5rem;
  }
`;
