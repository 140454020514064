import React, { useState, useEffect } from "react";
import {
  Container,
  Card,
  Col,
  Form,
  Row,
  Button,
  Alert,
  Modal,
  Table
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfra from "../../components/template/sidebar/sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
import Header from "../../components/template/header/header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import { useNavigate } from "react-router-dom";

const UserRegister = () => {
  const user = JSON.parse(sessionStorage.getItem("usuarioLogado"));
  const navigate = useNavigate();
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [sector, setSector] = useState("");
  const [unit, setUnit] = useState(user.unit);
  const [successMessage, setSuccessMessage] = useState(false);
  const [userType, setUserType] = useState("");
  const [typeGateway, setTypeGateway] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [userList, setUserList] = useState([]);
  const [successMessageDelete, setSuccessMessageDelete] = useState(false);
  const handleModalOpen = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);



  const handleSelectChange = (event) => {
    setUserType(event.target.value);
  };

  const handleSelectChangeUnit = (event) => {
    setUnit(event.target.value);
  };

  const handleSelectChangeGateway = (event) => {
    setTypeGateway(event.target.value);
  };

  const handleSelectChangeCompany = (event) => {
    setCompany(event.target.value);
  };

  const handleSubmit = async (e) => {
    console.clear();
    e.preventDefault();

    const obj = {
      name: nome,
      email: email,
      type: userType,
      company: company,
      companyName: companyName,
      sector: sector,
      unit: unit,
      type_gateway: typeGateway,
    };

    const submitButton = document.querySelector('.buttonCadaster');

    if (
      !nome ||
      !email ||
      !company ||
      !sector ||
      !unit ||
      !userType ||
      !typeGateway
    ) {
      alert("Por favor, preencha todos os campos obrigatórios.");
      return;
    }

    try {
      submitButton.disabled = true;

      const res = await Requests.createUser(obj);

      if (res) {
        console.clear();
        if (res.invalido) {
          alert(res.error);
        } else {
          setSuccessMessage(true);
          console.clear();
          setTimeout(() => {
            console.clear();
            let path = "/home";
            navigate(path);
          }, 350);
        }
      }
    } catch (err) {
      console.log(err);
      alert("Erro ao criar o usuário.");
    } finally {
      submitButton.disabled = false;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const users = await Requests.getUserList();

        const sortedUsers = users.sort((a, b) => a.name.localeCompare(b.name));
        setUserList(sortedUsers);
      } catch (error) {
        console.error("Erro ao obter a lista de usuários", error);
      }
    };

    fetchData();
  }, []);

  const handleDelete = (usersId) => {
    if (window.confirm("Tem certeza de que deseja excluir este usuário?")) {

      Requests.deleteUser(usersId)
        .then(() => {
          const updatedUsers = userList.filter((user) => user.id_user !== usersId);
          setUserList(updatedUsers);
          setSuccessMessageDelete(true);
        })
        .catch((error) => {
          console.error("Erro ao excluir o resíduo:", error);
        });
    }
  };


  if (!userAllowed(["Administrador"])) {
    window.location.href = "/home";
    alert("Não permitido para o seu usuário");
    return <></>;
  }

  return (
    <>
      <Header />
      <div style={{ display: "flex"}}>
      {/* maxHeight: "100vh"  */}
        <SidebarInfra />
        <MainContainer>
          <Col xs={12}>
            <Card className="cardUser">
              <CardUser>Cadastro de Usuários</CardUser>
              <SpanSubtitle>Cadastre novos usuários</SpanSubtitle>
            </Card>
          </Col>
          <Col xs={12} className="controlForm">
            <Container>
              {successMessage && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessage(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Usuário cadastrado com sucesso!
                </Alert>
              )}
              {successMessageDelete && (
                <Alert
                  variant="success"
                  onClose={() => setSuccessMessageDelete(false)}
                  dismissible
                >
                  <StyledFontAwesomeIcon icon={faCircleCheck} />
                  Usuário excluído com sucesso!
                </Alert>
              )}

              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicName">
                      <Form.Label className="labelControl">Nome</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Digite o nome do novo usuário"
                        value={nome}
                        onChange={(event) => setNome(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicEmail">
                      <Form.Label className="labelControl">Email</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Digite o email do novo usuário"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicType">
                      <Form.Label className="labelControl">Empresa</Form.Label>
                      <Form.Select
                        value={company}
                        required
                        onChange={handleSelectChangeCompany}
                        aria-label="Selecione um tipo"
                        style={{
                          height: "45px",
                        }}
                      >
                        <option value="" disabled>
                          Selecione uma opção
                        </option>
                        <option value="mosaic">Mosaic</option>
                        <option value="terceiro">Terceiros</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  {company === "terceiro" && (
                    <Col sm={6} xs={12}>
                      <Form.Group controlId="formBasicCompanyName">
                        <Form.Label className="labelControl">
                          Nome da Empresa
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={companyName}
                          onChange={(e) => setCompanyName(e.target.value)}
                          placeholder="Digite o nome da empresa"
                        />
                      </Form.Group>
                    </Col>
                  )}
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicSector">
                      <Form.Label className="labelControl">Setor</Form.Label>
                      <Form.Control
                        type="text"
                        required
                        placeholder="Informe o setor do novo usuário"
                        value={sector}
                        onChange={(event) => setSector(event.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row></Row>
                <Row>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicType">
                      <Form.Label className="labelControl">
                        Tipo de usuario
                      </Form.Label>
                      <Form.Select
                        value={userType}
                        required
                        onChange={handleSelectChange}
                        aria-label="Selecione um tipo"
                        style={{
                          height: "45px",
                        }}
                      >
                        <option value="" disabled>
                          Selecione uma opção
                        </option>
                        <option value="Administrador">Administrador</option>
                        <option value="Requisitante">Requisitante</option>
                        <option value="Aprovador">Aprovador</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicUnit">
                      <Form.Label className="labelControl">Unidade</Form.Label>
                      <Form.Select
                        value={unit}
                        required
                        onChange={(event) => handleSelectChangeUnit(event)}
                        style={{
                          height: "45px",
                        }}
                      >
                        <option value="" disabled>
                          Selecione a unidade
                        </option>
                        <option value="Araxá">Araxá</option>
                        <option value="Patrocinio">Patrocínio</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col sm={6} xs={12}>
                    <Form.Group controlId="formBasicType">
                      <Form.Label className="labelControl">
                        Tipo de portal
                      </Form.Label>
                      <Form.Select
                        value={typeGateway}
                        required
                        onChange={handleSelectChangeGateway}
                        aria-label="Selecione um tipo"
                        style={{
                          height: "45px",
                        }}
                      >
                        <option value="" disabled>
                          Selecione uma opção
                        </option>
                        <option value="todos">Todos</option>
                        <option value="restaurante">Restaurante</option>
                        <option value="ambiente">Meio ambiente</option>
                      </Form.Select>
                    </Form.Group>
                  </Col>
                </Row>
                <Col xs={12}>
                  <Row>
                    <Col sm={6} xs={12}>
                      <Button
                        variant="primary"
                        onClick={handleModalOpen}
                        className="buttonUsers"
                      >
                        Visualizar usuários cadastrados
                      </Button>

                    </Col>
                    <Col sm={6} xs={12} className="buttonForm">
                      <Button
                        variant="primary"
                        type="submit"
                        className="buttonCadaster"
                      >
                        Cadastrar
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Form>
            </Container>
          </Col>
        </MainContainer>

        <Modal show={showModal} onHide={handleModalClose} className="modal-xl">
          <Modal.Header closeButton>
            <Modal.Title>Usuários Cadastrados</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Email</th>
                  <th>Setor</th>
                  <th>Tipo</th>
                  <th>Excluir</th>
                </tr>
              </thead>
              <tbody>
                {userList.map((user) => (
                  <tr key={user.id}>
                    <td>{user.name}</td>
                    <td>{user.email}</td>
                    <td>{user.sector}</td>
                    <td>{user.type}</td>
                    <td className="text-center">
                      <Button
                        variant="danger"
                        onClick={() => {
                          handleDelete(user.id_user);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
        </Modal>

      </div>
    </>
  );
};

export default UserRegister;

const MainContainer = styled(Container)`
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
`;

const CardUser = styled.div`
  font-family: Helvetica Neue Medium Extended;
  font-size: 28px;
  font-weight: 5600;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Helvetica Neue Medium Extended;
  font-size: 18px;
  color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: #00544d;
  margin-right: 10px;
`;
