import React, { useState, useEffect } from "react";
import {
    Container,
    Card,
    Col,
    Row,
    Alert,
    Table,
    Form,
    Button,
    Modal,
    Pagination,
} from "react-bootstrap";
import styled from "styled-components";
import SidebarInfraEnvironment from "../../components/template/sidebarEnvironment/index";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "../../components/template/header/header";
import Requests from "../../configs/requets";
import { userAllowed } from "../../configs/userAllowed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEdit,
    faCircleCheck,
    faCirclePlus,
    faXmark
} from "@fortawesome/free-solid-svg-icons";

const RegistrationLeavings = () => {
    const [leavings, setLeavings] = useState([]);
    const [successMessageUpdate, setSuccessMessageUpdate] = useState(false);
    const [successMessageCreate, setSuccessMessageCreate] = useState(false);
    const [successMessageDelete, setSuccessMessageDelete] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [residuo, setResiduo] = useState("");
    const [code, setCode] = useState("");
    const [classLeavings, setClassLeavings] = useState("");
    const [stockLeavings, setStockLeavings] = useState("");
    const [currentItems, setCurrentItems] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 25;

    const updateCurrentItems = () => {
        const indexOfLastItem = currentPage * itemsPerPage;

        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const updatedCurrentItems = leavings.slice(
            indexOfFirstItem,
            indexOfLastItem
        );
        setCurrentItems(updatedCurrentItems);
    };

    useEffect(() => {
        updateCurrentItems();
        // eslint-disable-next-line
    }, [currentPage, leavings]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setCurrentItems([]);
    };

    const handleCloseModal = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);

    const handleSelectChangeClass = (event) => {
        setClassLeavings(event.target.value);
    };

    const fetchLeavings = async () => {
        try {
            const response = await Requests.getLeavingsList();
            const updateLeavings = response.map((residuo) => ({
                ...residuo,
                isEditable: false,
            }));

            updateLeavings.sort((a, b) =>
                a.name_leavings.localeCompare(b.name_leavings)
            );
            setLeavings(updateLeavings);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchLeavings();
        // eslint-disable-next-line
    }, []);

    const cadasterLeavings = async (event) => {
        try {
            const newLeavings = {
                code_leavings: code,
                name_leavings: residuo,
                class_leavings: classLeavings,
                stock_leavings: stockLeavings,
            };

            const response = await Requests.createLeavings(newLeavings);
            setResiduo([...leavings, response]);

            setCode("");
            setResiduo("");
            setClassLeavings("");
            setStockLeavings("");
            setShowModal(false);
            setSuccessMessageCreate(true);

            fetchLeavings();
        } catch (error) {
            console.error(error);
        }
    };

    const handleEdit = (id) => {
        const updatedLeavings = leavings.map((leavings) => {
            if (leavings.id_leavings === id) {
                return {
                    ...leavings,
                    isEditable: true,
                };
            } else {
                return {
                    ...leavings,
                    isEditable: false,
                };
            }
        });
        setLeavings(updatedLeavings);
    };

    const handleSave = async (
        leavingId,
        code_leavings,
        name_leavings,
        class_leavings,
        stock_leavings
    ) => {
        try {
            const updatedLeavings = leavings.map((leavings) => {
                if (leavings.id_leavings === leavingId) {
                    return {
                        ...residuo,
                        isEditable: false,
                        code_leavings: code_leavings,
                        name_leavings: name_leavings,
                        class_leavings: class_leavings,
                        stock_leavings: stock_leavings,
                    };
                } else {
                    return residuo;
                }
            });

            await Requests.updateLeavings(leavingId, {
                code_leavings,
                name_leavings,
                class_leavings,
                stock_leavings,
            });

            setLeavings(updatedLeavings);
            setSuccessMessageUpdate(true);

            fetchLeavings();
        } catch (error) {
            console.error(error);
        }
    };

    const handleDelete = (leavingId) => {
        if (window.confirm("Tem certeza de que deseja excluir este resíduo?")) {

            Requests.deleteLeavings(leavingId)
                .then(() => {
                    const updatedLeavings = leavings.filter((leaving) => leaving.id_leavings !== leavingId);
                    setLeavings(updatedLeavings);
                    setSuccessMessageDelete(true);
                })
                .catch((error) => {
                    console.error("Erro ao excluir o resíduo:", error);
                });
        }
    };

    if (!userAllowed(["Administrador"])) {
        window.location.href = "/home-environment";
        alert("Não permitido para o seu usuário");
        return <></>;
    }

    return (
        <>
            <Header />
            <div style={{ display: "flex", minHeight: "100vh" }}>
                <SidebarInfraEnvironment />
                <MainContainer>
                    <Col xs={12}>
                        <Card className="cardUser">
                            <CardUser>Cadastrar/Alterar Resíduos</CardUser>
                            <SpanSubtitle>
                                Cadastre ou altere seus resíduos
                            </SpanSubtitle>
                        </Card>
                    </Col>
                    <Col xs={12} className="controlForm">
                        <Container>
                            {successMessageUpdate && (
                                <Alert
                                    variant="success"
                                    onClose={() =>
                                        setSuccessMessageUpdate(false)
                                    }
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon
                                        icon={faCircleCheck}
                                    />
                                    Resíduo alterado com sucesso!
                                </Alert>
                            )}
                            {successMessageCreate && (
                                <Alert
                                    variant="success"
                                    onClose={() =>
                                        setSuccessMessageCreate(false)
                                    }
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon
                                        icon={faCircleCheck}
                                    />
                                    Resíduo criado com sucesso!
                                </Alert>
                            )}
                            {successMessageDelete && (
                                <Alert
                                    variant="success"
                                    onClose={() => setSuccessMessageDelete(false)}
                                    dismissible
                                >
                                    <StyledFontAwesomeIcon icon={faCircleCheck} />
                                    Resíduo excluído com sucesso!
                                </Alert>
                            )}

                            <Col
                                xs={12}
                                className="position-button-products mb-2"
                            >
                                <Row>
                                    <Col xs={12}>
                                        <Button
                                            variant="primary"
                                            type="submit"
                                            className="buttonProducts"
                                            onClick={handleOpenModal}
                                        >
                                            <FontAwesomeIcon
                                                icon={faCirclePlus}
                                                style={{ marginRight: "10px" }}
                                            />
                                            Cadastrar resíduo
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Modal
                                show={showModal}
                                onHide={handleCloseModal}
                                centered
                            >
                                <Modal.Header closeButton>
                                    <Modal.Title>
                                        Cadastro de Resíduo
                                    </Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Form>
                                        <Col sm={12} xs={12}>
                                            <Form.Group controlId="formBasicProduct">
                                                <Form.Label className="labelControl">
                                                    Código do Resíduo:
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Digite o código do resíduo"
                                                    value={code}
                                                    onChange={(event) =>
                                                        setCode(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} xs={12}>
                                            <Form.Group controlId="formBasicProduct">
                                                <Form.Label className="labelControl">
                                                    Nome
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Digite o nome do resíduo"
                                                    value={residuo}
                                                    onChange={(event) =>
                                                        setResiduo(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col sm={12} xs={12}>
                                            <Form.Group controlId="formBasicType">
                                                <Form.Label className="labelControl">
                                                    Classe
                                                </Form.Label>
                                                <Form.Select
                                                    value={classLeavings}
                                                    required
                                                    onChange={
                                                        handleSelectChangeClass
                                                    }
                                                    aria-label="Selecione um tipo"
                                                >
                                                    <option value="" disabled>
                                                        Selecione uma opção de
                                                        classe
                                                    </option>
                                                    <option value="1">1</option>
                                                    <option value="2">2</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col sm={12} xs={12}>
                                            <Form.Group controlId="formBasicProduct">
                                                <Form.Label className="labelControl">
                                                    Qtd estoque:
                                                </Form.Label>
                                                <Form.Control
                                                    type="number"
                                                    placeholder="Informe a quantidade em estoque"
                                                    value={stockLeavings}
                                                    onChange={(event) =>
                                                        setStockLeavings(
                                                            event.target.value
                                                        )
                                                    }
                                                />
                                            </Form.Group>
                                        </Col> */}
                                        <Button
                                            className="buttonProducts"
                                            variant="primary"
                                            onClick={cadasterLeavings}
                                        >
                                            Cadastrar
                                        </Button>
                                    </Form>
                                </Modal.Body>
                            </Modal>
                            <Table
                                striped
                                bordered
                                hover
                                className="table-waste"
                            >
                                <thead style={{ textAlign: "center" }}>
                                    <tr>
                                        <th>Código</th>
                                        <th>Nome</th>
                                        {/* <th className="table-class">Classe</th> */}
                                        {/* <th>Qtd estoque</th> */}
                                        <th>Editar</th>
                                        <th>Excluir</th>
                                    </tr>
                                </thead>
                                <tbody style={{ textAlign: "center" }}>
                                    {currentItems.map((leavings) => (
                                        <tr key={leavings.id_leavings}>
                                            <td>
                                                {leavings.isEditable ? (
                                                    <Form.Control
                                                        type="text"
                                                        value={
                                                            leavings.code_leavings
                                                        }
                                                        onChange={(event) => {
                                                            const newCode =
                                                                event.target
                                                                    .value;
                                                            setLeavings(
                                                                (
                                                                    prevLeavings
                                                                ) =>
                                                                    prevLeavings.map(
                                                                        (
                                                                            prevLeaving
                                                                        ) =>
                                                                            prevLeaving.id_leavings ===
                                                                                leavings.id_leavings
                                                                                ? {
                                                                                    ...prevLeaving,
                                                                                    code_leavings:
                                                                                        newCode,
                                                                                }
                                                                                : prevLeaving
                                                                    )
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    leavings.code_leavings
                                                )}
                                            </td>
                                            <td>
                                                {leavings.isEditable ? (
                                                    <Form.Control
                                                        type="text"
                                                        value={
                                                            leavings.name_leavings
                                                        }
                                                        onChange={(event) => {
                                                            const newName =
                                                                event.target
                                                                    .value;
                                                            setLeavings(
                                                                (
                                                                    prevLeavings
                                                                ) =>
                                                                    prevLeavings.map(
                                                                        (
                                                                            prevLeaving
                                                                        ) =>
                                                                            prevLeaving.id_leavings ===
                                                                                leavings.id_leavings
                                                                                ? {
                                                                                    ...prevLeaving,
                                                                                    name_leavings:
                                                                                        newName,
                                                                                }
                                                                                : prevLeaving
                                                                    )
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    leavings.name_leavings
                                                )}
                                            </td>
                                            {/* <td>
                                                {leavings.isEditable ? (
                                                    <Form.Control
                                                        type="number"
                                                        step="0.01"
                                                        min="0"
                                                        value={
                                                            leavings.stock_leavings
                                                        }
                                                        onChange={(event) => {
                                                            const newStock =
                                                                parseFloat(
                                                                    event.target
                                                                        .value
                                                                );
                                                            setLeavings(
                                                                (
                                                                    prevLeavings
                                                                ) =>
                                                                    prevLeavings.map(
                                                                        (
                                                                            prevLeaving
                                                                        ) =>
                                                                            prevLeaving.id_leavings ===
                                                                                leavings.id_leavings
                                                                                ? {
                                                                                    ...prevLeaving,
                                                                                    stock_leavings:
                                                                                        newStock,
                                                                                }
                                                                                : prevLeaving
                                                                    )
                                                            );
                                                        }}
                                                    />
                                                ) : (
                                                    leavings.stock_leavings
                                                )}
                                            </td> */}
                                            <td>
                                                {leavings.isEditable ? (
                                                    <Button
                                                        variant="success"
                                                        onClick={() => {
                                                            handleSave(
                                                                leavings.id_leavings,
                                                                leavings.code_leavings,
                                                                leavings.name_leavings,
                                                                leavings.class_leavings,
                                                                leavings.stock_leavings
                                                            );
                                                        }}
                                                    >
                                                        Salvar
                                                    </Button>
                                                ) : (
                                                    <StyledButton
                                                        variant="primary"
                                                        onClick={() => {
                                                            handleEdit(
                                                                leavings.id_leavings
                                                            );
                                                        }}
                                                    >
                                                        <StyledFontAwesome
                                                            icon={faEdit}
                                                        />
                                                    </StyledButton>
                                                )}
                                            </td>
                                            <td>
                                                <Button
                                                    variant="danger"
                                                    onClick={() => {
                                                        handleDelete(leavings.id_leavings);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon={faXmark} />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            {leavings.length > itemsPerPage && (
                                <Pagination className="pagination-style">
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={() =>
                                            handlePageChange(currentPage - 1)
                                        }
                                        disabled={currentPage === 1}
                                        className="button-pagination-pass"
                                    >
                                        {"<"}
                                    </Button>
                                    {Array.from({
                                        length: Math.ceil(
                                            leavings.length / itemsPerPage
                                        ),
                                    }).map((_, index) => (
                                        <Pagination.Item
                                            className="button-pagination"
                                            key={index}
                                            active={index + 1 === currentPage}
                                            onClick={() =>
                                                handlePageChange(index + 1)
                                            }
                                        >
                                            {index + 1}
                                        </Pagination.Item>
                                    ))}
                                    <Button
                                        variant="primary"
                                        type="button"
                                        onClick={() =>
                                            handlePageChange(currentPage + 1)
                                        }
                                        disabled={
                                            currentPage ===
                                            Math.ceil(
                                                leavings.length / itemsPerPage
                                            )
                                        }
                                        className="button-pagination-pass"
                                    >
                                        {">"}
                                    </Button>
                                </Pagination>
                            )}
                        </Container>
                    </Col>
                </MainContainer>
            </div>
        </>
    );
};

export default RegistrationLeavings;

const MainContainer = styled(Container)`
    padding-top: 20px;
    padding-bottom: 20px;
    position: relative;
`;

const CardUser = styled.div`
    font-family: Helvetica Neue Medium Extended;
    font-size: 28px;
    font-weight: 5600;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
`;

const SpanSubtitle = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Helvetica Neue Medium Extended;
    font-size: 18px;
    color: white;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
    color: #00544d;
    margin-right: 10px;
`;

const StyledFontAwesome = styled(FontAwesomeIcon)`
    color: #fff;
`;

const StyledButton = styled(Button)`
    background-color: rgb(48, 151, 143);
    border: none;
    color: #fff;
    &:hover {
        background-color: rgb(0, 84, 77);
    }
`;
